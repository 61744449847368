import loadGoogleMapsApi from "creators/utils/loadGoogleMapsApi";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Dropdown, { IProps as IDropdownProps } from "vui/components/Dropdown";
import TextInput from "vui/components/TextInput";
import { IOption } from "vui/types/dropdown";

const StyledDropdown = styled(Dropdown)`
  z-index: 1;
`;

export interface IProps
  extends Omit<IDropdownProps, "options" | "onChange" | "value"> {
  onChange: (value: string) => void;
  value?: string;
}

const LocationInput = forwardRef<HTMLInputElement, IProps>(
  ({ onChange, value, ...props }, ref) => {
    const [apiLoaded, setApiLoaded] = useState(false);
    const [error, setError] = useState(false);
    const [googleMapsApiError, setGoogleMapsApiError] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      const loadAPI = async () => {
        try {
          await loadGoogleMapsApi();

          setApiLoaded(true);
        } catch {
          setGoogleMapsApiError(true);
        }
      };

      loadAPI();
    }, []);

    const service = useMemo(() => {
      if (apiLoaded && window.google) {
        return new window.google.maps.places.AutocompleteService();
      }

      return null;
    }, [apiLoaded]);

    const [options, setOptions] = useState<IOption[] | null>(null);

    const handleSearchPerformed = useCallback(
      async (value: string) => {
        setError(false);
        setLoading(true);

        try {
          const response = await service?.getPlacePredictions({
            input: value,
            types: ["(cities)"],
          });

          if (response?.predictions) {
            const newOptions = response.predictions.map((prediction: any) => ({
              label: prediction.description,
              value: prediction.description,
            }));

            setOptions(newOptions);
          }
        } catch {
          setError(true);
        } finally {
          setLoading(false);
        }
      },
      [service],
    );

    const handleChange = useCallback(
      (value: string) => {
        onChange(value);

        if (value === "") {
          setOptions(null);
        }
      },
      [onChange],
    );

    if (!apiLoaded || googleMapsApiError) {
      // Text input field to avoid shifting layout when loaded & allow the user to enter location manually
      return (
        <TextInput
          {...props}
          onChange={(e) => handleChange(e.target.value)}
          ref={ref}
          value={value}
        />
      );
    }

    return (
      <StyledDropdown
        {...props}
        asyncError={error}
        loading={loading}
        onChange={(option) => handleChange(option?.value || "")}
        onSearchPerformed={handleSearchPerformed}
        options={options}
        ref={ref}
        value={value ? { label: value, value } : undefined}
      />
    );
  },
);

export default LocationInput;
