import {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  KeyboardEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Icon from "vui/components/Icon";
import MenuItem from "vui/components/MenuItem";
import TextInput from "vui/components/TextInput";
import KEYBOARD_SHORTCUTS from "vui/constants/keyboardShortcuts";
import CheckIcon from "vui/icons/check.svg";
import ErrorIcon from "vui/icons/error.svg";
import { IOption } from "vui/types/dropdown";
import normalizeString from "vui/utils/normalizeString";
import textualize from "vui/utils/textualize";
import {
  Container,
  LoadingIcon,
  MenuClosedIcon,
  MenuOpenIcon,
  Message,
  Options,
} from "./styles";

export interface IProps {
  asyncError?: boolean;
  asyncErrorMessage?: ReactNode;
  className?: string;
  hasError?: boolean;
  id?: string;
  label?: ReactNode;
  loading?: boolean;
  noResultsMessage?: ReactNode;
  onBlur?: () => void;
  onChange: (option: IOption | null) => void;
  onSearchPerformed?: (searchTerm: string) => void;
  options: IOption[] | null;
  required?: boolean;
  supportingText?: ReactNode;
  value?: IOption;
}

export const Dropdown = forwardRef<HTMLInputElement, IProps>(
  (
    {
      asyncError,
      asyncErrorMessage,
      className,
      hasError,
      id,
      label,
      loading,
      noResultsMessage = textualize("dropdown.noResults"),
      onBlur,
      onChange,
      onSearchPerformed: onSearchPerformed,
      options,
      required,
      supportingText,
      value,
    },
    ref,
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(ref, () => containerRef.current as HTMLInputElement);

    const inputRef = useRef<HTMLInputElement>(null);
    const optionsRef = useRef<HTMLUListElement>(null);

    const [filter, setFilter] = useState<string | null>(null);
    const [open, setOpen] = useState(false);

    const [dropdownPosition, setDropdownPosition] = useState<"above" | "below">(
      "below",
    );

    useLayoutEffect(() => {
      // Logic for placing the dropdown above or below the input
      if (!open || !containerRef.current || !optionsRef.current) {
        return;
      }

      const containerRect = containerRef.current.getBoundingClientRect();
      const optionsHeight = optionsRef.current.offsetHeight;
      const spaceBelow = window.innerHeight - containerRect.bottom;
      const spaceAbove = containerRect.top;

      // Determine dropdown position based on available space
      const newPosition =
        spaceBelow < optionsHeight && spaceAbove > optionsHeight
          ? "above"
          : "below";

      // Only update state if the position has changed
      if (newPosition !== dropdownPosition) {
        setDropdownPosition(newPosition);
      }
    }, [open, dropdownPosition]);

    useEffect(() => {
      if (!onSearchPerformed || !filter) {
        return;
      }

      const debouncedSearchTermChange = window.setTimeout(() => {
        onSearchPerformed(filter);
      }, 300);

      return () => clearTimeout(debouncedSearchTermChange);
    }, [filter, onSearchPerformed]);

    const handleOptionsClose = useCallback(() => {
      setOpen(false);

      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    const handleInputClick = useCallback(() => {
      if (!open) {
        setOpen(true);
      } else if (!filter) {
        setOpen(false);
      }
    }, [filter, open]);

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
      (event) => {
        if (event.key === KEYBOARD_SHORTCUTS.ARROW_DOWN) {
          if (!open) {
            setOpen(true);
          }

          if (optionsRef.current) {
            optionsRef.current.focus();
          }
        }
      },
      [open],
    );

    const handleFilterChange: ChangeEventHandler<HTMLInputElement> =
      useCallback(
        (event) => {
          const value = event.target.value;

          if (value === "") {
            // Clear field
            onChange(null);
          }

          setOpen(true);
          setFilter(value);
        },
        [onChange],
      );

    const handleContainerBlur: FocusEventHandler<HTMLDivElement> = useCallback(
      (event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setFilter(null);
          setOpen(false);

          onBlur?.();
        }
      },
      [onBlur],
    );

    const chooseOption = useCallback(
      (option: IOption) => {
        onChange(option);

        setFilter(null);
        setOpen(false);
      },
      [onChange],
    );

    const filteredOptions = useMemo(() => {
      if (options === null) {
        return null;
      }

      if (filter === null) {
        return options;
      }

      return options.filter((option) =>
        normalizeString(option.label).includes(normalizeString(filter)),
      );
    }, [filter, options]);

    const message = useMemo(() => {
      if (asyncError) {
        return (
          <Message>{asyncErrorMessage || textualize("dropdown.error")}</Message>
        );
      }

      if (filteredOptions && filteredOptions.length === 0) {
        return <Message>{noResultsMessage}</Message>;
      }

      return null;
    }, [asyncError, asyncErrorMessage, filteredOptions, noResultsMessage]);

    const trailingElement = useMemo(() => {
      if (asyncError) {
        return <Icon component={ErrorIcon} />;
      }

      if (loading) {
        return (
          <Icon
            component={LoadingIcon}
            label={textualize("dropdown.loading")}
          />
        );
      }

      if (!options) {
        return null;
      }

      if (open) {
        return <Icon component={MenuOpenIcon} />;
      }

      return <Icon component={MenuClosedIcon} />;
    }, [asyncError, loading, open, options]);

    return (
      <Container
        className={className}
        onBlur={handleContainerBlur}
        ref={containerRef}
      >
        <TextInput
          aria-expanded={open}
          hasError={hasError}
          id={id}
          label={label}
          onChange={handleFilterChange}
          onClick={handleInputClick}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          required={required}
          role="combobox"
          supportingText={supportingText}
          trailingElement={trailingElement}
          value={filter !== null ? filter : value ? value.label : ""}
        />

        {open && (!!filteredOptions || message) && (
          <Options
            dropdownPosition={dropdownPosition}
            onClose={handleOptionsClose}
            ref={optionsRef}
            role="listbox"
            tabIndex={-1}
          >
            {message ||
              filteredOptions?.map((option) => {
                const { disabled, icon, value: optionValue, label } = option;

                const selected = value && value.value === optionValue;

                return (
                  <MenuItem
                    disabled={disabled}
                    key={optionValue}
                    leadingIcon={icon}
                    onClick={() => chooseOption(option)}
                    role="option"
                    selected={selected}
                    trailingIcon={selected ? CheckIcon : undefined}
                  >
                    {label}
                  </MenuItem>
                );
              })}
          </Options>
        )}
      </Container>
    );
  },
);

export default Dropdown;
