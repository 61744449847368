import { useMutation } from "@apollo/client";
import CurrencyForm from "creators/components/OnboardingForms/CurrencyForm";
import LocationForm from "creators/components/OnboardingForms/LocationForm";
import PayeeNameForm from "creators/components/OnboardingForms/PayeeNameForm";
import PortfolioForm from "creators/components/OnboardingForms/PortfolioForm";
import SkillsForm from "creators/components/OnboardingForms/SkillsForm";
import IDS from "creators/constants/ids";
import { TOTAL_STEPS } from "creators/constants/onboarding";
import useOnboardingProgressAnalytics from "creators/hooks/useOnboardingProgressAnalytics";
import { UPDATE_CREATOR } from "creators/mutations/updateCreator";
import { UpdateCreatorMutation as IUpdateCreator } from "creators/types/graphql";
import { IFormValues, IOnboarding } from "creators/types/onboarding";
import textualize from "creators/utils/textualize";
import { useCallback, useContext, useState } from "react";
import { SnackbarContext } from "vui/components/SnackbarProvider";

interface ICreatorPaymentDetails {
  payeeName: string;
}

interface IValues extends Omit<IFormValues, "payeeName"> {
  creatorPaymentDetails?: ICreatorPaymentDetails;
}

const OnboardingSteps: React.FC<React.PropsWithChildren<IOnboarding>> = ({
  creatorExternalID,
  currency,
  location,
  payeeName,
  portfolio,
  skills,
}) => {
  const calculateStep = useCallback(() => {
    // Navigate to the first unfilled onboarding step
    if (!skills || skills.length < 1) {
      return 0;
    } else if (!portfolio) {
      return 1;
    } else if (!location) {
      return 2;
    } else if (!currency) {
      return 3;
    } else {
      return 4;
    }
  }, [currency, location, portfolio, skills]);

  const [stepIndex, setStepIndex] = useState(calculateStep);
  const { addSnackbar } = useContext(SnackbarContext);

  const [updateCreator] = useMutation<IUpdateCreator>(UPDATE_CREATOR);

  useOnboardingProgressAnalytics(creatorExternalID, stepIndex);

  const navigateToPreviousStep = useCallback(
    () => setStepIndex(stepIndex - 1),
    [stepIndex],
  );

  const navigateToNextStep = useCallback(
    () => setStepIndex(stepIndex + 1),
    [stepIndex],
  );

  const onSubmit = useCallback(
    async ({ payeeName, ...values }: IFormValues) => {
      const newValues: Partial<IValues> = {
        ...values,
      };

      if (payeeName) {
        newValues.creatorPaymentDetails = { payeeName };
      }

      try {
        const result = await updateCreator({
          variables: {
            input: {
              ...newValues,
            },
          },
        });

        if (result?.data?.viewerUpdateCreator) {
          if (stepIndex + 1 !== TOTAL_STEPS) {
            navigateToNextStep();
          }

          return Promise.resolve();
        }
      } catch {
        addSnackbar(textualize("onboarding.error") as string);
      }

      return Promise.reject();
    },
    [addSnackbar, navigateToNextStep, stepIndex, updateCreator],
  );

  const steps = [
    <SkillsForm
      creatorExternalID={creatorExternalID}
      initialValues={{ skills }}
      key={IDS.ONBOARDING.SKILLS.ID}
      onNext={navigateToNextStep}
      onSubmit={onSubmit}
    />,
    <PortfolioForm
      creatorExternalID={creatorExternalID}
      initialValues={{ portfolio }}
      key={IDS.ONBOARDING.PORTFOLIO.ID}
      onBack={navigateToPreviousStep}
      onNext={navigateToNextStep}
      onSubmit={onSubmit}
    />,
    <LocationForm
      creatorExternalID={creatorExternalID}
      initialValues={{ location }}
      key={IDS.ONBOARDING.LOCATION.ID}
      onBack={navigateToPreviousStep}
      onNext={navigateToNextStep}
      onSubmit={onSubmit}
    />,
    <CurrencyForm
      creatorExternalID={creatorExternalID}
      initialValues={{ currency }}
      key={IDS.ONBOARDING.CURRENCY.ID}
      onBack={navigateToPreviousStep}
      onNext={navigateToNextStep}
      onSubmit={onSubmit}
    />,
    <PayeeNameForm
      initialValues={{ payeeName }}
      key={IDS.ONBOARDING.PAYEE_NAME.ID}
      onBack={navigateToPreviousStep}
      onSubmit={onSubmit}
    />,
  ];

  return <>{steps[stepIndex]}</>;
};

export default OnboardingSteps;
