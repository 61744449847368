import { useMutation, useQuery } from "@apollo/client";
import DataError from "creators/components/DataError";
import LoadingDelayed from "creators/components/LoadingDelayed";
import {
  PrivacyPolicyLink,
  TermsAndConditionsLink,
} from "creators/components/TermsAndConditionsLinks";
import IDS from "creators/constants/ids";
import { UPDATE_CREATOR } from "creators/mutations/updateCreator";
import { GET_VIEWER } from "creators/queries/GetViewer";
import {
  EmailVerificationStage,
  GetViewerQuery as IGetViewer,
  UpdateCreatorMutation as IUpdateCreator,
} from "creators/types/graphql";
import onboardingIncomplete from "creators/utils/onboardingIncomplete";
import textualize from "creators/utils/textualize";
import { useCallback, useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Redirect } from "react-router";
import styled from "styled-components";
import { FilledButton } from "vui/components/Button";
import Heading from "vui/components/Heading";
import { SnackbarContext } from "vui/components/SnackbarProvider";
import SwitchInput from "vui/components/SwitchInput";

const Form = styled.form`
  display: block;
  margin-top: var(--vui-spacing-12);
`;

const StyledHeading = styled(Heading)`
  margin-bottom: var(--vui-spacing-6);
`;

const StyledButton = styled(FilledButton)`
  margin-top: var(--vui-spacing-4);
`;

export interface Inputs {
  termsAndConditionsAccepted: boolean;
}

const TermsAndConditions: React.FC = () => {
  const [submitted, setSubmitted] = useState(false);

  const { data, loading, error } = useQuery<IGetViewer>(GET_VIEWER);
  const [updateCreator] = useMutation<IUpdateCreator>(UPDATE_CREATOR);

  const { addSnackbar } = useContext(SnackbarContext);

  const {
    formState: { isDirty },
    handleSubmit,
    register,
  } = useForm<Inputs>({
    defaultValues: {
      termsAndConditionsAccepted: false,
    },
    mode: "onBlur",
  });

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    async (data) => {
      try {
        await updateCreator({
          variables: {
            input: {
              termsAndConditionsAccepted: data.termsAndConditionsAccepted,
            },
          },
        });
        setSubmitted(true);
      } catch {
        addSnackbar(
          textualize("vuiOnboarding.termsAndConditions.error") as string,
        );
      }
    },
    [addSnackbar, updateCreator],
  );

  if (loading) {
    return <LoadingDelayed />;
  }

  if (error) {
    return <DataError error={error} />;
  }

  if (!data?.viewer) {
    return null;
  }

  if (data.viewer.termsAndConditionsAccepted || submitted) {
    let redirectURI = "/";
    if (
      data.viewer.emailVerificationStage !== EmailVerificationStage.Verified
    ) {
      redirectURI = "/verify-email";
    } else if (onboardingIncomplete(data.viewer)) {
      redirectURI = "/onboarding";
    }
    return <Redirect to={redirectURI} />;
  }

  return (
    <div>
      <Heading appearance="large" level="1">
        {textualize("vuiOnboarding.welcome", {
          firstName: data.viewer.firstName,
        })}
      </Heading>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <StyledHeading appearance="medium" level="2">
          {textualize("vuiOnboarding.termsAndConditions.heading")}
        </StyledHeading>

        <SwitchInput
          id={IDS.TERMS_AND_CONDITIONS.AGREE}
          label={textualize("vuiCommon.termsAndConditions.agreement", {
            privacyPolicy: (
              <PrivacyPolicyLink id={IDS.TERMS_AND_CONDITIONS.PRIVACY_POLICY} />
            ),
            termsAndConditions: (
              <TermsAndConditionsLink id={IDS.TERMS_AND_CONDITIONS.LINK} />
            ),
          })}
          {...register("termsAndConditionsAccepted")}
        />

        <StyledButton disabled={!isDirty} type="submit">
          {textualize("vuiOnboarding.termsAndConditions.save")}
        </StyledButton>
      </Form>
    </div>
  );
};

export default TermsAndConditions;
