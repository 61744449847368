import styled, { css } from "styled-components";

export const SwitchInput = styled.input<{
  hasError: boolean;
  pressed?: boolean;
}>`
  appearance: none;
  background: transparent;
  border: var(--vui-spacing-0-5) solid
    rgb(var(--vui-color-on-surface-secondary));
  border-radius: var(--vui-shape-corner-full);
  cursor: pointer;
  display: inline-block;
  height: var(--vui-spacing-8);
  margin: 0;
  position: relative;
  width: calc(var(--vui-spacing-12) + var(--vui-spacing-2));
  transition: all ease-in-out 100ms;

  ${({ hasError }) =>
    hasError &&
    css`
      border-color: rgb(var(--vui-color-sentiment-negative));
    `};

  ::before {
    background: ${({ hasError }) =>
      hasError
        ? "rgb(var(--vui-color-sentiment-negative))"
        : "rgb(var(--vui-color-on-surface-secondary))"};
    border-radius: var(--vui-shape-corner-full);
    content: "";
    height: var(--vui-spacing-4);
    left: 0;
    margin: calc(var(--vui-spacing-1) + var(--vui-spacing-0-5));
    position: absolute;
    width: var(--vui-spacing-4);
    transition: all ease-in-out 100ms;
  }

  :checked {
    background: ${({ hasError }) =>
      hasError
        ? "rgb(var(--vui-color-sentiment-negative))"
        : "rgb(var(--vui-color-sentiment-positive))"};
    border-color: ${({ hasError }) =>
      hasError
        ? "rgb(var(--vui-color-sentiment-negative))"
        : "rgb(var(--vui-color-sentiment-positive))"};

    ::before {
      background: ${({ hasError }) =>
        hasError
          ? "rgb(var(--vui-color-on-sentiment-negative))"
          : "rgb(var(--vui-color-on-sentiment-positive))"};
      height: var(--vui-spacing-5);
      margin: var(--vui-spacing-1);
      transform: translateX(calc(var(--vui-spacing-4) + var(--vui-spacing-2)));
      width: var(--vui-spacing-5);
    }
  }

  :not(:checked, :disabled) {
    :hover {
      background-color: rgb(var(--vui-color-surface-tertiary));

      ::before {
        background: ${({ hasError }) =>
          hasError
            ? "rgb(var(--vui-color-sentiment-negative))"
            : "rgb(var(--vui-color-on-surface-tertiary))"};
      }
    }
  }

  :not(:disabled) {
    ${({ hasError, pressed }) =>
      pressed &&
      css`
        ::before {
          transform: scale(1.75);
        }

        :checked {
          ::before {
            background-color: ${hasError
              ? "rgb(var(--vui-color-on-sentiment-negative))"
              : "rgb(var(--vui-color-on-sentiment-positive))"};
            transform: translateX(
                calc(var(--vui-spacing-4) + var(--vui-spacing-2))
              )
              scale(1.25);
          }
        }
      `}
  }

  :disabled {
    border-color: rgba(var(--vui-color-disabled-surface-outline), 0.5);
    cursor: default;

    :focus-visible {
      box-shadow: none;
    }

    ::before {
      background-color: rgba(var(--vui-color-disabled-on-surface), 0.5);
    }

    :checked {
      background: rgb(var(--vui-color-disabled-surface));
      border-color: rgb(var(--vui-color-disabled-surface));
    }
  }

  :focus-visible {
    box-shadow: 0 0 0 0.25rem rgb(var(--vui-color-on-surface-secondary));
    outline: none;
  }
`;
