import DIMENSIONS from "creators/constants/dimensions";
import { forwardRef, HTMLAttributes } from "react";
import styled from "styled-components";

const Modal = styled.div`
  align-self: center;
  background: rgb(var(--vui-color-surface-primary));
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-items: center;
  max-height: 100%;
  overflow: auto;
  padding: var(--vui-spacing-4);

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    padding: var(--vui-spacing-8);
  }

  &:focus {
    outline: none;
  }
`;

const StandardModal = forwardRef(
  (
    {
      children,
      className,
    }: React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>,
    ref: React.MutableRefObject<HTMLDivElement>,
  ) => {
    return (
      <Modal className={className} ref={ref}>
        {children}
      </Modal>
    );
  },
);

export default StandardModal;
