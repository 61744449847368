import BriefCard from "creators/components/BriefCard";
import DataError from "creators/components/DataError";
import LoadingDelayed from "creators/components/LoadingDelayed";
import IDS from "creators/constants/ids";
import { GET_LIVE_BRIEFS } from "creators/queries/GetLiveBriefs";
import {
  BriefType,
  GetLiveBriefsQuery as IGetLiveBriefs,
} from "creators/types/graphql";
import textualize from "creators/utils/textualize";
import { useCallback, useContext, useState } from "react";
import useLoadMoreQuery from "shared/hooks/useLoadMoreQuery";
import { SnackbarContext } from "vui/components/SnackbarProvider";
import VisuallyHidden from "vui/components/VisuallyHidden";
import IconDesktop from "vui/icons/desktop.svg";
import IconVideocam from "vui/icons/videocam.svg";
import {
  BriefListGrid,
  LoadMoreButton,
  LoadMoreWrapper,
  NoResults,
  NoResultsIcon,
} from "./styles";

export interface IProps {
  alternative: BriefType | null;
  id?: string;
  maxPerPage: number;
  tabIndex?: number;
  title: string;
  type: BriefType;
}

const skillsToValues = {
  [BriefType.LiveAction]: textualize("vuiCommon.briefType.cgc") as string,
  [BriefType.MotionGraphics]: textualize("vuiCommon.briefType.remix") as string,
};

const skillsToIcons = {
  [BriefType.LiveAction]: IconVideocam,
  [BriefType.MotionGraphics]: IconDesktop,
};

const BriefList: React.FC<IProps> = ({
  alternative,
  id,
  maxPerPage: MAX_PER_PAGE,
  tabIndex,
  title,
  type,
}) => {
  const { addSnackbar } = useContext(SnackbarContext);

  const [loadBriefs, setLoadBriefs] = useState(false);

  // TODO: Look at how we can cache the fetched results to avoid refetching on tab change
  const { data, loading, error, loadMore } = useLoadMoreQuery<IGetLiveBriefs>(
    GET_LIVE_BRIEFS,
    MAX_PER_PAGE,
    {
      extraVariables: {
        type,
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    },
  );

  const loadMoreBriefs = useCallback(async () => {
    try {
      setLoadBriefs(true);

      await loadMore();
    } catch {
      addSnackbar(textualize("error.loadMore") as string);
    } finally {
      setLoadBriefs(false);
    }
  }, [addSnackbar, loadMore]);

  if (error) {
    return <DataError error={error} />;
  }

  if (loading) {
    return <LoadingDelayed />;
  }

  if (!data?.liveBriefsV2?.edges || data.liveBriefsV2.edges.length < 1) {
    return (
      <div id={id}>
        <NoResults id={IDS.LIVE_BRIEFS.NO_RESULTS}>
          <NoResultsIcon component={skillsToIcons[type]} />
          {alternative
            ? textualize("vuiLiveBriefs.noResults.multiple", {
                alternative: skillsToValues[alternative],
                briefType: skillsToValues[type],
              })
            : textualize("vuiLiveBriefs.noResults.single", {
                briefType: skillsToValues[type],
              })}
        </NoResults>
      </div>
    );
  }

  const moreBriefsAvailable = data.liveBriefsV2.pageInfo.hasNextPage;

  const briefs = data.liveBriefsV2.edges.map(({ node }) => {
    const {
      brand,
      creatorSelectionDate,
      id: briefID,
      paymentAmount,
      slug,
      state,
      submission,
      title,
      type,
    } = node;

    return {
      brandImage: brand.imageURI,
      brandName: brand.name,
      brandID: brand.id,
      briefID,
      briefState: state,
      briefTitle: title,
      briefType: type,
      creatorSelectionDate:
        creatorSelectionDate && new Date(creatorSelectionDate),
      paymentAmount: paymentAmount.amount,
      paymentCurrencyCode: paymentAmount.currencyCode,
      slug,
      submissionState: submission ? submission.state : undefined,
      submittedAt: submission?.submittedAt
        ? new Date(submission.submittedAt)
        : undefined,
    };
  });

  return (
    <>
      <VisuallyHidden as="h2">{title}</VisuallyHidden>

      <BriefListGrid id={id} tabIndex={tabIndex}>
        {briefs.map((brief) => {
          return (
            <li key={brief.briefID}>
              <BriefCard {...brief} />
            </li>
          );
        })}

        {moreBriefsAvailable && (
          <LoadMoreWrapper>
            <LoadMoreButton
              loading={loading || loadBriefs}
              onClick={loadMoreBriefs}
            >
              {textualize("vuiCommon.button.loadMore")}
            </LoadMoreButton>
          </LoadMoreWrapper>
        )}
      </BriefListGrid>
    </>
  );
};

export default BriefList;
