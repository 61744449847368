import { useMutation, useQuery } from "@apollo/client";
import BriefHeader from "creators/components/BriefHeader";
import BriefTeaserFooter from "creators/components/BriefTeaserFooter";
import BriefTeaserNotFound from "creators/components/BriefTeaserNotFound";
import BriefTeaserSummary from "creators/components/BriefTeaserSummary";
import DataError from "creators/components/DataError";
import LoadingDelayed from "creators/components/LoadingDelayed";
import ANALYTICS from "creators/constants/analytics";
import ERRORS from "creators/constants/errors";
import IDS from "creators/constants/ids";
import APPLY_TO_BRIEF from "creators/mutations/applyToBrief";
import WITHDRAW_BRIEF_APPLICATION from "creators/mutations/withdrawBriefApplication";
import { BriefAnalyticsContext } from "creators/providers/BriefAnalyticsProvider";
import { GET_BRIEF_TEASER } from "creators/queries/GetBriefTeaser";
import { GET_VIEWER } from "creators/queries/GetViewer";
import {
  BriefState,
  BriefType,
  ApplyToBriefMutation as IApplyToBrief,
  GetBriefTeaserQuery as IGetBriefTeaser,
  GetViewerQuery as IGetViewer,
  WithdrawBriefApplicationMutation as IWithdrawBriefApplication,
  SubmissionState,
} from "creators/types/graphql";
import textualize from "creators/utils/textualize";
import { isBefore } from "date-fns";
import { useCallback, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import { Block } from "shared/BlockBuilder/global";
import { collateSections, ICollatedSection } from "shared/BlockBuilder/utils";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import generateBlockID from "shared/utils/generateBlockID";
import getGraphQLErrors from "shared/utils/getGraphQLErrors";
import { SnackbarContext } from "vui/components/SnackbarProvider";
import {
  BriefContent,
  BriefLayout,
  Introduction,
  MediaSection,
  StyledBlockConductor,
  StyledHeading,
  StyledWidget,
  TeaserSection,
} from "./styles";

export interface IProps {
  briefID: string;
  previewHash?: string;
  slug?: string;
}

const BriefTeaser: React.FC<IProps> = ({ briefID, previewHash, slug }) => {
  const { briefAnalytics, cleanAnalyticsData, saveAnalyticsData } = useContext(
    BriefAnalyticsContext,
  );
  const { addSnackbar } = useContext(SnackbarContext);

  const { data: userData } = useQuery<IGetViewer>(GET_VIEWER);
  const { data, loading, error } = useQuery<IGetBriefTeaser>(GET_BRIEF_TEASER, {
    variables: { id: briefID, previewHash },
  });
  const [
    applyToBriefMutation,
    { loading: applyBriefApplicationMutationLoading },
  ] = useMutation<IApplyToBrief>(APPLY_TO_BRIEF);

  const [
    withdrawBriefApplicationMutation,
    { loading: withdrawBriefApplicationMutationLoading },
  ] = useMutation<IWithdrawBriefApplication>(WITHDRAW_BRIEF_APPLICATION);

  const [displaySurvey, setDisplaySurvey] = useState(false);
  const [teaserViewedEventFired, setTeaserViewedEventFired] = useState(false);

  useEffect(() => {
    if (!data) {
      return;
    }

    saveAnalyticsData(data.brief);
  }, [cleanAnalyticsData, data, saveAnalyticsData]);

  useEffect(() => {
    // Fire analytics event on Brief Teaser load
    // Catch if briefAnalytics is empty to avoid firing event
    if (Object.keys(briefAnalytics).length === 0 || teaserViewedEventFired) {
      return;
    }

    fireAnalyticsEvent(
      ANALYTICS.CATEGORIES.LIVE_BRIEFS,
      ANALYTICS.EVENT.TEASER_VIEWED,
      briefAnalytics,
    );

    setTeaserViewedEventFired(true);
  }, [briefID, briefAnalytics, teaserViewedEventFired]);

  const showTeaserSurvey =
    data?.brief.type === BriefType.LiveAction &&
    data?.brief.liveActionSurveyLink;

  const handleApplicationEvent = useCallback(async () => {
    try {
      const result = await applyToBriefMutation({
        variables: {
          input: {
            id: briefID,
          },
        },
      });

      if (result?.data?.applyToBrief) {
        addSnackbar(
          textualize("liveBriefs.teaser.footer.applied.success") as string,
        );
        fireAnalyticsEvent(
          ANALYTICS.CATEGORIES.LIVE_BRIEFS,
          ANALYTICS.EVENT.APPLY_TO_BRIEF,
          briefAnalytics,
        );
      }
    } catch {
      addSnackbar(textualize("liveBriefs.teaser.footer.open.error") as string);
    }
  }, [addSnackbar, applyToBriefMutation, briefID, briefAnalytics]);

  const handleApplicationClick = useCallback(async () => {
    if (showTeaserSurvey) {
      setDisplaySurvey(true);
      return;
    }

    handleApplicationEvent();
  }, [handleApplicationEvent, showTeaserSurvey]);

  const handleWithdrawClick = useCallback(async () => {
    try {
      const result = await withdrawBriefApplicationMutation({
        variables: {
          input: {
            briefID: briefID,
          },
        },
      });

      if (result.data?.withdrawBriefApplication) {
        addSnackbar(
          textualize("liveBriefs.teaser.footer.withdraw.success") as string,
        );
        fireAnalyticsEvent(
          ANALYTICS.CATEGORIES.LIVE_BRIEFS,
          ANALYTICS.EVENT.BRIEF_WITHDRAW_APPLICATION,
          briefAnalytics,
        );
      }
    } catch {
      addSnackbar(
        textualize("liveBriefs.teaser.footer.withdraw.error") as string,
      );
    }
  }, [addSnackbar, withdrawBriefApplicationMutation, briefID, briefAnalytics]);

  const isPreview = !!previewHash;

  if (!isPreview) {
    // Redirect users that attempt to access a teaser of a brief
    // that they are currently working on or a completed brief
    if (
      data?.brief?.submission &&
      [SubmissionState.Invited, SubmissionState.InviteAccepted].includes(
        data.brief.submission.state,
      )
    ) {
      return <Redirect to="/active-brief" />;
    } else if (data?.brief?.submission?.state === SubmissionState.Completed) {
      const redirectURI = data?.brief?.submission?.submittedAt
        ? `/previous-briefs/${briefID}/${data.brief.slug}`
        : "/";
      return <Redirect to={redirectURI} />;
    }

    // Update slug in URL if missing or incorrect
    if (data?.brief?.slug && data.brief.slug !== slug) {
      return <Redirect to={`/brief/${briefID}/${data?.brief.slug}`} />;
    }
  }

  if (error) {
    if (getGraphQLErrors(error).includes(ERRORS.NOT_FOUND)) {
      return <BriefTeaserNotFound />;
    } else {
      return <DataError error={error} />;
    }
  }

  if (loading) {
    return <LoadingDelayed />;
  }

  const brief = data!.brief;
  const {
    brand,
    creatorSelectionDate,
    liveActionSurveyLink,
    numberOfCreators,
    paymentAmount,
    state,
    submission,
    submissionDate,
    teaser,
    title,
    type,
    viewerPaymentAmount,
  } = brief;
  const { creatorsDeliverables, introduction, requirements } = teaser;

  let introductionSections: ICollatedSection[] = [];
  let introductionText: ICollatedSection[] = [];
  let introductionMedia: ICollatedSection[] = [];

  const compileTeaserContent = (
    oldData: ICollatedSection[],
    newData: Block[],
    parent: Block,
  ) => {
    return [
      ...oldData,
      {
        children: newData,
        section: {
          ...parent,
          content: newData.map((block) => block.id),
        },
      },
    ];
  };

  try {
    introductionSections = collateSections(JSON.parse(introduction));
    for (const item of introductionSections) {
      const section = item.section;
      const textContent = [];
      const mediaContent = [];

      if (item.children === undefined) {
        continue;
      }

      for (const child of item.children) {
        if (child.type === "image" || child.type === "video") {
          mediaContent.push(child);
        } else {
          textContent.push(child);
        }
      }

      introductionText = compileTeaserContent(
        introductionText,
        textContent,
        section,
      );
      introductionMedia = compileTeaserContent(
        introductionMedia,
        mediaContent,
        section,
      );
    }
  } catch {
    // Keep sections empty
  }

  const applicationsClosed =
    state === BriefState.Closed ||
    (creatorSelectionDate && isBefore(creatorSelectionDate, new Date()));

  const selectionDeadline = creatorSelectionDate
    ? new Date(creatorSelectionDate)
    : undefined;

  const submissionDeadline = submissionDate
    ? new Date(submissionDate)
    : undefined;

  const summaryContents = {
    assets: requirements.assets || "",
    creatorsDeliverables: creatorsDeliverables,
    featuring: requirements.featuring || "",
    location: requirements.location || "",
    numberOfCreators: numberOfCreators,
    paymentAmount: paymentAmount.amount,
    paymentCurrencyCode: paymentAmount.currencyCode,
    propsOrItems: requirements.propsOrItems || "",
    selectionDeadline: selectionDeadline,
    submissionDeadline: submissionDeadline,
    type: type,
    viewerPaymentAmount: viewerPaymentAmount.amount,
    viewerPaymentCurrencyCode: viewerPaymentAmount.currencyCode,
  };

  return (
    <>
      <Helmet title={`${brand.name} - ${title}`} />

      {!displaySurvey && (
        <BriefLayout id={IDS.BRIEF_TEASER.PAGE}>
          <BriefHeader
            brandImage={brand.imageURI}
            brandName={brand.name}
            briefName={title}
            briefState={state}
            briefType={type}
            creatorSelectionDate={
              creatorSelectionDate && new Date(creatorSelectionDate)
            }
            paymentAmount={paymentAmount.amount}
            paymentCurrencyCode={paymentAmount.currencyCode}
            submissionState={submission ? submission.state : undefined}
            submittedAt={
              submission?.submittedAt
                ? new Date(submission.submittedAt)
                : undefined
            }
          />

          <BriefContent>
            <div>
              <section>
                <StyledHeading appearance="small" level="2">
                  Brief details
                </StyledHeading>
                <Introduction>
                  {introductionText.map(({ children, section }, index) => (
                    <TeaserSection
                      block={section}
                      disableHeader={true}
                      id={generateBlockID(
                        IDS.BRIEF_TEASER.INTRODUCTION.BLOCK,
                        index,
                      )}
                      key={section.id}
                    >
                      {children?.map((child, blockIndex) => (
                        <StyledBlockConductor
                          block={child}
                          id={generateBlockID(
                            IDS.BRIEF_TEASER.INTRODUCTION.BLOCK,
                            index,
                            child.type,
                            blockIndex,
                          )}
                          key={child.id}
                        />
                      ))}
                    </TeaserSection>
                  ))}
                </Introduction>
              </section>

              <BriefTeaserSummary {...summaryContents} />
            </div>

            <div>
              {introductionMedia.map(({ children, section }, index) => (
                <MediaSection
                  block={section}
                  disableHeader={true}
                  id={generateBlockID(
                    IDS.BRIEF_TEASER.INTRODUCTION.BLOCK,
                    index,
                  )}
                  key={section.id}
                >
                  {children?.map((child, blockIndex) => (
                    <StyledBlockConductor
                      block={child}
                      id={generateBlockID(
                        IDS.BRIEF_TEASER.INTRODUCTION.BLOCK,
                        index,
                        child.type,
                        blockIndex,
                      )}
                      key={child.id}
                    />
                  ))}
                </MediaSection>
              ))}
            </div>
          </BriefContent>

          {!isPreview && (
            <BriefTeaserFooter
              applicationsClosed={applicationsClosed}
              applied={
                submission && submission.state === SubmissionState.Applied
                  ? true
                  : false
              }
              applyLoading={applyBriefApplicationMutationLoading}
              creatorSelectionDate={
                creatorSelectionDate && new Date(creatorSelectionDate)
              }
              handleApplicationClick={handleApplicationClick}
              handleWithdrawClick={handleWithdrawClick}
              withdrawLoading={withdrawBriefApplicationMutationLoading}
            />
          )}
        </BriefLayout>
      )}

      {displaySurvey && (
        <StyledWidget
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={true}
          hidden={{
            // snake case required for Typeform hidden fields
            /* eslint-disable @typescript-eslint/naming-convention */
            creator_name: `${userData!.viewer!.firstName} ${
              userData!.viewer!.lastName
            }`,
            creator_user_id: userData!.viewer!.id,
            /* eslint-enable @typescript-eslint/naming-convention */
          }}
          hideHeaders={true}
          id={new URL(liveActionSurveyLink!).pathname.split("/")[2]}
          iframeProps={{ id: IDS.BRIEF_TEASER.CGC.SURVEY }}
          inlineOnMobile={true}
          onSubmit={() => {
            handleApplicationEvent();
            setDisplaySurvey(false);
          }}
        />
      )}
    </>
  );
};

export default BriefTeaser;
