import { ApolloProvider } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { FloatingDelayGroup } from "@floating-ui/react";
import Analytics from "creators/components/Analytics";
import CrashReporterBoundary from "creators/components/CrashReporterBoundary";
import HealthCheck from "creators/components/HealthCheck";
import Intercom from "creators/components/Intercom";
import LDIdentifier from "creators/components/LDIdentifier";
import LoadingDelayed from "creators/components/LoadingDelayed";
import Routes from "creators/components/Routes";
import SnackbarContainer from "creators/components/SnackbarContainer";
import DATADOG from "creators/constants/datadog";
import INTERCOM from "creators/constants/intercom";
import ViewerProvider from "creators/providers/ViewerProvider";
import ViewportProvider from "creators/providers/ViewportProvider";
import client from "creators/services/GraphQL/client";
import ldClient from "creators/services/LaunchDarkly/client";
import GlobalStyle from "creators/styling/GlobalStyle";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import { Globals } from "react-spring";
import { IntercomProvider } from "react-use-intercom";
import FeatureProvider from "shared/components/FeatureProvider";
import ModalProvider from "shared/components/ModalProvider";
import ScrollToTop from "shared/components/ScrollToTop";
import useReduceMotion from "shared/hooks/useReduceMotion";
import Environment from "shared/types/environment";
import getDatadogBeforeSend from "shared/utils/getDatadogBeforeSend";
import getEnvironment from "shared/utils/getEnvironment";
import textualize from "shared/utils/textualize";
import SnackbarProvider from "vui/components/SnackbarProvider";

// Don't scroll the homepage to the top when the teasers are shown
const teaserPathTransitions = [
  {
    from: "/",
    to: "/brief/:id(\\d+)/:slug?",
  },
  {
    from: "/brief/:id(\\d+)/:slug?",
    to: "/",
  },
];

const tooltipDelay = { open: 0, close: 1500 };

function App() {
  const environment = getEnvironment();
  const prefersReducedMotion = useReduceMotion();

  if (environment !== Environment.Development) {
    datadogRum.init({
      allowedTracingUrls: DATADOG.CONFIG.ALLOWED_TRACING_URLS,
      applicationId: DATADOG.CONFIG.APPLICATION_ID,
      beforeSend: getDatadogBeforeSend(client, DATADOG),
      clientToken: DATADOG.CONFIG.CLIENT_TOKEN,
      defaultPrivacyLevel: DATADOG.CONFIG.DEFAULT_PRIVACY_LEVEL,
      env: environment,
      service: DATADOG.CONFIG.SERVICE,
      sessionSampleRate: DATADOG.CONFIG.SESSION_SAMPLE_RATE,
      sessionReplaySampleRate: DATADOG.CONFIG.SESSION_REPLAY_SAMPLE_RATE,
      site: DATADOG.CONFIG.SITE,
      trackUserInteractions: DATADOG.CONFIG.TRACK_USER_INTERACTIONS,
      version: `${process.env.VERSION}`,
    });
  }

  if (prefersReducedMotion) {
    // Immediately complete all react-spring animations
    Globals.assign({ skipAnimation: true });
  }

  const intercomAppID = INTERCOM[environment];

  let appTitle = textualize("general.appTitle") as string;

  if (environment === Environment.Development) {
    appTitle = `${appTitle} DEV`;
  }

  return (
    <BrowserRouter>
      <Helmet defaultTitle={appTitle} titleTemplate={`%s - ${appTitle}`} />
      <IntercomProvider appId={intercomAppID} autoBoot={true}>
        <ApolloProvider client={client}>
          <FeatureProvider
            client={ldClient}
            loadingComponent={<LoadingDelayed />} // TODO: Confirm update to new style at VUI launch as flag not available here
          >
            <HealthCheck>
              <CrashReporterBoundary>
                <ViewportProvider>
                  <ViewerProvider>
                    <FloatingDelayGroup delay={tooltipDelay}>
                      <ModalProvider>
                        <SnackbarProvider>
                          <SnackbarContainer />
                          <Routes />
                        </SnackbarProvider>
                      </ModalProvider>
                    </FloatingDelayGroup>
                    <Intercom />
                  </ViewerProvider>
                </ViewportProvider>
              </CrashReporterBoundary>
              {environment !== Environment.Development && <Analytics />}
              <LDIdentifier client={ldClient} />
            </HealthCheck>
            <GlobalStyle />
          </FeatureProvider>
        </ApolloProvider>
      </IntercomProvider>
      <ScrollToTop ignorePathTransitions={teaserPathTransitions} />
    </BrowserRouter>
  );
}

export default App;
