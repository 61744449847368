import { useEffect, useState } from "react";

export interface IOptions {
  /** Offset at which the nav element would be treated as hidden */
  offsetHidden: number;
  /** Offset at which the nav element would be treated as sticky */
  offsetSticky: number;
}

const useStickyNav = (element: HTMLElement, options: IOptions) => {
  const { offsetHidden = 0, offsetSticky = 0 } = options;

  const [isHidden, setIsHidden] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    if (!element) {
      return;
    }

    const controlNavbar = () => {
      if (window.scrollY > offsetSticky) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      if (window.scrollY > offsetHidden) {
        if (window.scrollY < lastScrollY) {
          setIsHidden(false);
        } else {
          setIsHidden(true);
        }
      } else {
        setIsHidden(false);
      }

      // Remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    };

    window.addEventListener("scroll", controlNavbar);

    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [element, lastScrollY, offsetHidden, offsetSticky]);

  return {
    isHidden,
    isSticky,
  };
};

export default useStickyNav;
