import { forwardRef, FunctionComponent, PropsWithChildren } from "react";
import { Container, Item, LeadingIcon, TrailingIcon } from "./styles";

export interface IProps {
  className?: string;
  disabled?: boolean;
  id?: string;
  leadingIcon?: FunctionComponent | string;
  onClick?: () => void;
  role?: "menuitem" | "option";
  tabIndex?: number;
  selected?: boolean;
  trailingIcon?: FunctionComponent | string;
}

export const MenuItem = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<IProps>
>(
  (
    {
      children,
      className,
      disabled = false,
      id,
      leadingIcon,
      onClick,
      role = "menuitem",
      selected = false,
      tabIndex,
      trailingIcon,
    },
    ref,
  ) => {
    let leadingIconContent;
    let trailingIconContent;

    if (typeof leadingIcon === "string") {
      leadingIconContent = (
        <LeadingIcon
          alt=""
          as="img"
          isDisabled={disabled}
          isSelected={selected}
          src={leadingIcon}
        />
      );
    } else if (leadingIcon !== undefined) {
      leadingIconContent = (
        <LeadingIcon
          component={leadingIcon}
          isDisabled={disabled}
          isSelected={selected}
        />
      );
    }

    if (typeof trailingIcon === "string") {
      trailingIconContent = (
        <TrailingIcon
          alt=""
          as="img"
          isDisabled={disabled}
          isSelected={selected}
          src={trailingIcon}
        />
      );
    } else if (trailingIcon !== undefined) {
      trailingIconContent = (
        <TrailingIcon
          component={trailingIcon}
          isDisabled={disabled}
          isSelected={selected}
        />
      );
    }

    return (
      <Container className={className} id={id} role="presentation">
        <Item
          disabled={disabled}
          onClick={onClick}
          ref={ref}
          role={role}
          selected={selected}
          tabIndex={tabIndex}
          type="button"
        >
          {leadingIconContent}

          {children}

          {trailingIconContent}
        </Item>
      </Container>
    );
  },
);

export default MenuItem;
