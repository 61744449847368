import styled, { css } from "styled-components";

const HorizontalSubNavLink = styled.a<{ $active?: boolean }>`
  border-radius: var(--vui-shape-corner-full);
  color: rgb(var(--vui-color-on-surface-secondary));
  display: block;
  flex-grow: 1;
  height: 100%;

  :focus-visible {
    outline: rgb(var(--vui-color-surface-tertiary-outline)) solid
      var(--vui-state-focus-indicator-thickness);
    outline-offset: calc(-1.5 * var(--vui-state-focus-indicator-outer-offset));
  }

  :hover {
    background-color: rgb(var(--vui-color-surface-tertiary));
    color: rgb(var(--vui-color-on-surface-tertiary));
  }

  ${({ $active }) =>
    $active &&
    css`
      background-color: rgb(var(--vui-color-inverse-surface));
      color: rgb(var(--vui-color-inverse-on-surface));

      :hover {
        background-color: rgb(var(--vui-color-active-surface));
        color: rgb(var(--vui-color-active-on-surface));
      }
    `}
`;

export default HorizontalSubNavLink;
