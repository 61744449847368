// TODO: Replace with VUI VideoPlayer
import OnboardingVideoPlayer from "creators/components/OnboardingVideoPlayer";
import ANALYTICS from "creators/constants/analytics";
import EXTERNAL_LINKS from "creators/constants/externalLinks";
import IDS from "creators/constants/ids";
import { ONBOARDING_INDICES, TOTAL_STEPS } from "creators/constants/onboarding";
import VIDEOS from "creators/constants/videos";
import useCurrencyOptions from "creators/hooks/useCurrencyOptions";
import { getStepForCreator } from "creators/hooks/useOnboardingProgressAnalytics";
import { IFormValues } from "creators/types/onboarding";
import textualize from "creators/utils/textualize";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import Dropdown from "vui/components/Dropdown";
import ProgressBar from "vui/components/ProgressBar";
import {
  Back,
  Next,
  ResponsiveLayout,
  StyledHeading,
  VideoContainer,
} from "../styles";

export interface IProps {
  creatorExternalID: string;
  initialValues: IFormValues;
  onBack: () => void;
  onNext: () => void;
  onSubmit: (values: IFormValues) => Promise<void>;
}

const CurrencyForm: React.FC<React.PropsWithChildren<IProps>> = ({
  creatorExternalID,
  initialValues,
  onBack,
  onNext,
  onSubmit,
}) => {
  const currencyOptions = useCurrencyOptions();

  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = useForm<IFormValues>({
    defaultValues: initialValues,
    mode: "onChange",
  });

  const [videoInteraction, setVideoInteraction] = useState(false);
  const [currencyArticleClick, setCurrencyArticleClick] = useState(false);

  const onFormSubmit = useCallback(
    async (values: IFormValues) => {
      if (initialValues.currency === values.currency) {
        onNext();
      } else {
        try {
          await onSubmit(values);

          if (getStepForCreator(creatorExternalID) === 3) {
            fireAnalyticsEvent(
              ANALYTICS.CATEGORIES.ONBOARDING,
              ANALYTICS.EVENT.ONBOARDING_CURRENCY,
              {
                currencyArticleClick,
                videoInteraction,
              },
            );
          }
        } catch {
          // Handled in OnboardingSteps
        }
      }
    },
    [
      creatorExternalID,
      currencyArticleClick,
      initialValues.currency,
      onNext,
      onSubmit,
      videoInteraction,
    ],
  );

  const currencySupportingText = textualize("onboarding.currency.help", {
    link: (
      <a
        href={EXTERNAL_LINKS.PAYMENT_CURRENCY}
        id={IDS.ONBOARDING.CURRENCY.LINK}
        onClick={() => setCurrencyArticleClick(true)}
        rel="noopener noreferrer"
        target="_blank"
      >
        {textualize("onboarding.currency.link")}
      </a>
    ),
  }) as string;

  return (
    <ResponsiveLayout>
      <div>
        <ProgressBar
          completed={false}
          count={ONBOARDING_INDICES.CURRENCY}
          id={IDS.ONBOARDING.PROGRESS}
          label={textualize("onboarding.progress") as string}
          totalRequired={TOTAL_STEPS}
        />

        <StyledHeading appearance="medium" level="2">
          {textualize("onboarding.currency.heading")}
        </StyledHeading>

        <form
          id={IDS.ONBOARDING.CURRENCY.ID}
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <Controller
            control={control}
            name="currency"
            render={({ field }) => (
              <Dropdown
                {...field}
                hasError={!!errors.currency}
                id={IDS.ONBOARDING.CURRENCY.INPUT}
                label={textualize("onboarding.currency.label")}
                onChange={(option) => {
                  field.onChange(option?.value || "");
                }}
                options={currencyOptions}
                supportingText={
                  errors.currency?.message || currencySupportingText
                }
                value={currencyOptions.find(
                  (option) => option.value === field.value,
                )}
              />
            )}
            rules={{
              required: textualize("onboarding.currency.error") as string,
            }}
          />

          <Back id={IDS.ONBOARDING.BACK} onClick={onBack}>
            {textualize("onboarding.back")}
          </Back>

          <Next
            disabled={isSubmitting || !isValid}
            id={IDS.ONBOARDING.NEXT}
            type="submit"
          >
            {textualize("onboarding.next")}
          </Next>
        </form>
      </div>
      <VideoContainer>
        <OnboardingVideoPlayer
          captions={VIDEOS.CURRENCY.CAPTIONS}
          id={IDS.ONBOARDING.CURRENCY.VIDEO}
          onVideoInteraction={() => setVideoInteraction(true)}
          src={VIDEOS.CURRENCY.SOURCE}
        />
      </VideoContainer>
    </ResponsiveLayout>
  );
};

export default CurrencyForm;
