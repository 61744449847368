import { Widget } from "@typeform/embed-react";
import BlockConductor from "creators/components/Brief/BlockConductor";
import BriefSection from "creators/components/BriefSection";
import DIMENSIONS from "creators/constants/dimensions";
import styled, { css } from "styled-components";
import Heading from "vui/components/Heading";
import BREAKPOINTS from "vui/constants/breakpoints";

const richTextEditorStyles = css`
  display: block;

  aside {
    padding: var(--vui-spacing-2);

    &.danger {
      background-color: rgb(var(--vui-color-palette-negative-variant));
    }
    &.success {
      background-color: rgb(var(--vui-color-palette-positive-variant));
    }
    &.warning {
      background-color: rgb(var(--vui-color-palette-cautionary-variant));
    }
  }

  h1 {
    font: var(--vui-type-title-medium-shorthand);
    letter-spacing: var(--vui-type-title-medium-spacing);
  }

  & .dropdown__value-container,
  & .dropdown__single-value,
  h2 {
    margin-bottom: var(--vui-spacing-8);

    /* Temp fix to ignore the non-VUI styles until we either fully migrate BlockConductor or replace it with its VUI equivalent  */
    @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
      all: unset;
    }

    @media (min-width: ${BREAKPOINTS.BREAKPOINT_LG}) {
      margin: 0 auto;
      margin-bottom: var(--vui-spacing-12);
      max-width: 33.75rem;
    }
  }

  iframe,
  img {
    max-width: 100%;
  }

  div.ProseMirror {
    font: var(--vui-type-body-shorthand);
    letter-spacing: var(--vui-type-body-spacing);

    p {
      margin-bottom: var(--vui-spacing-2);

      &.centered {
        text-align: center;
      }
    }

    ol {
      list-style: decimal;
      margin-left: var(--vui-spacing-6);
    }
    ul {
      list-style: disc;
      margin-left: var(--vui-spacing-6);
    }
  }
`;

export const Introduction = styled.div`
  ${richTextEditorStyles};
  width: 100%;

  > *:not(:last-child) {
    margin-bottom: var(--vui-spacing-2);
  }
`;

export const TeaserSection = styled(BriefSection)`
  background: transparent;
  color: rgb(var(--vui-color-on-surface-primary));

  & > * {
    margin: 0;
    max-width: unset;
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    padding: 0;
  }
`;

export const MediaSection = styled(BriefSection)`
  background: transparent;
  color: rgb(var(--vui-color-on-surface-primary));

  /* 
    This is incredibly blanket to get all images lined up in one column.
    From testing, all appears to be correctly spaced, but will need to revisit.
  */
  * {
    display: flex;
    flex-direction: column;
    grid-gap: 0;
    grid-row-gap: var(--vui-spacing-2);
    grid-template-columns: 1fr;
  }

  & > *:not(*:last-child) {
    margin-bottom: var(--vui-spacing-2);
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    padding: 0;
  }
`;

export const StyledBlockConductor = styled(BlockConductor)`
  all: unset;
  ${richTextEditorStyles};
`;

export const BriefLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--vui-spacing-6);
  padding: 0 var(--vui-spacing-4);
  min-height: calc(100dvh - var(--vui-spacing-16));

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_SM}) {
    padding: 0 var(--vui-spacing-8);
  }

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_MD}) {
    gap: var(--vui-spacing-16);
    min-height: unset;
    padding: 0;
  }
`;

export const BriefContent = styled.div`
  display: grid;
  gap: var(--vui-spacing-12);

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_LG}) {
    grid-template-columns: minmax(auto, 29rem) minmax(auto, 14rem);
    margin: 0 auto;
    max-width: 47rem;
  }
`;

export const StyledHeading = styled(Heading)`
  margin-bottom: var(--vui-spacing-2);
`;

export const StyledWidget = styled(Widget)`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  & iframe {
    border-radius: 0 !important;
    height: calc(100vh - (var(--vui-spacing-4) * 2)) !important;
  }
`;
