import Snackbar from "creators/components/Snackbar";
import { useContext } from "react";
import ReactDom from "react-dom";
import { animated, useTransition } from "react-spring";
import styled from "styled-components";
import { SnackbarContext } from "vui/components/SnackbarProvider";

export const StyledContainer = styled.div`
  align-items: center;
  bottom: var(--vui-spacing-2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 50%;
  pointer-events: none;
  position: fixed;
  transform: translateX(-50%);
  z-index: 100;
`;

export const AnimatedSnackbar = animated(Snackbar);

const transitionValues = {
  enter: {
    height: "auto",
    marginBottom: "1rem",
    opacity: 1,
    padding: "0.75rem",
    transform: "translateY(0px)",
  },
  from: {
    height: 0,
    marginBottom: "0rem",
    opacity: 0,
    padding: "0.75rem",
    transform: "translateY(100%)",
  },
  leave: [
    { opacity: 0, transform: "translateY(25%)" },
    { height: 0, marginBottom: "0rem", padding: "0rem" },
  ],
};

export const SnackbarContainer = () => {
  const { removeSnackbar = () => null, snackbars } =
    useContext(SnackbarContext);

  const transitions = useTransition(snackbars, {
    keys: (item) => item.id,
    ...transitionValues,
  });

  const node = (
    <StyledContainer>
      {transitions((props, item, { key }) => (
        <AnimatedSnackbar
          key={key}
          message={item.message}
          onClick={() => removeSnackbar(item.id)}
          style={props}
        />
      ))}
    </StyledContainer>
  );

  return ReactDom.createPortal(
    node,
    document.getElementById("snackbar-root") as Element,
  );
};

export default SnackbarContainer;
