// TODO: Replace with VUI VideoPlayer
import OnboardingVideoPlayer from "creators/components/OnboardingVideoPlayer";
import ANALYTICS from "creators/constants/analytics";
import IDS from "creators/constants/ids";
import { ONBOARDING_INDICES, TOTAL_STEPS } from "creators/constants/onboarding";
import VIDEOS from "creators/constants/videos";
import { getStepForCreator } from "creators/hooks/useOnboardingProgressAnalytics/useOnboardingProgressAnalytics";
import { IFormValues } from "creators/types/onboarding";
import normalizeUrl from "creators/utils/normalizeUrl";
import textualize from "creators/utils/textualize";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { URL_REGEX } from "shared/constants/regex";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import ProgressBar from "vui/components/ProgressBar";
import TextInput from "vui/components/TextInput";
import {
  Back,
  Next,
  ResponsiveLayout,
  StyledHeading,
  VideoContainer,
} from "../styles";

export interface IProps {
  creatorExternalID: string;
  initialValues: IFormValues;
  onBack: () => void;
  onNext: () => void;
  onSubmit: (values: IFormValues) => Promise<void>;
}

const PortfolioForm: React.FC<React.PropsWithChildren<IProps>> = ({
  creatorExternalID,
  initialValues,
  onBack,
  onNext,
  onSubmit,
}) => {
  const [videoInteraction, setVideoInteraction] = useState(false);

  const {
    control,
    formState: { errors, isValid, isSubmitting },
    handleSubmit,
    trigger,
  } = useForm<IFormValues>({
    defaultValues: initialValues,
    mode: "onBlur",
  });

  const onFormSubmit = useCallback(
    async (values: IFormValues) => {
      if (initialValues.portfolio === values.portfolio) {
        onNext();
      } else {
        try {
          await onSubmit(values);

          if (getStepForCreator(creatorExternalID) === 1) {
            fireAnalyticsEvent(
              ANALYTICS.CATEGORIES.ONBOARDING,
              ANALYTICS.EVENT.ONBOARDING_PORTFOLIO,
              {
                videoInteraction,
              },
            );
          }
        } catch {
          // Handled in OnboardingSteps
        }
      }
    },
    [
      creatorExternalID,
      initialValues.portfolio,
      onNext,
      onSubmit,
      videoInteraction,
    ],
  );

  return (
    <ResponsiveLayout>
      <div>
        <ProgressBar
          completed={false}
          count={ONBOARDING_INDICES.PORTFOLIO}
          id={IDS.ONBOARDING.PROGRESS}
          label={textualize("onboarding.progress") as string}
          totalRequired={TOTAL_STEPS}
        />

        <StyledHeading appearance="medium" level="2">
          {textualize("onboarding.portfolio.heading")}
        </StyledHeading>

        <form
          id={IDS.ONBOARDING.PORTFOLIO.ID}
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <Controller
            control={control}
            name="portfolio"
            render={({ field }) => (
              <TextInput
                {...field}
                hasError={!!errors.portfolio}
                id={IDS.ONBOARDING.PORTFOLIO.INPUT}
                label={textualize("vuiOnboarding.portfolio.label")}
                onBlur={(e) => {
                  const url = normalizeUrl(e);
                  field.onChange(url);
                  trigger(field.name);
                }}
                supportingText={
                  errors.portfolio
                    ? errors.portfolio.message
                    : textualize("vuiOnboarding.portfolio.help")
                }
              />
            )}
            rules={{
              pattern: {
                value: URL_REGEX,
                message: textualize(
                  "onboarding.portfolio.error.invalid",
                ) as string,
              },
              required: textualize(
                "onboarding.portfolio.error.required",
              ) as string,
            }}
          />

          <Back id={IDS.ONBOARDING.BACK} onClick={onBack}>
            {textualize("onboarding.back")}
          </Back>

          <Next
            disabled={isSubmitting || !isValid}
            id={IDS.ONBOARDING.NEXT}
            type="submit"
          >
            {textualize("onboarding.next")}
          </Next>
        </form>
      </div>
      <VideoContainer>
        <OnboardingVideoPlayer
          captions={VIDEOS.PORTFOLIO.CAPTIONS}
          id={IDS.ONBOARDING.PORTFOLIO.VIDEO}
          onVideoInteraction={() => setVideoInteraction(true)}
          src={VIDEOS.PORTFOLIO.SOURCE}
        />
      </VideoContainer>
    </ResponsiveLayout>
  );
};

export default PortfolioForm;
