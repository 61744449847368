import { useMemo } from "react";
import { ProgressBarLine, ProgressBarWrapper } from "./styles";

export interface IProps {
  className?: string;
  completed: boolean;
  count: number;
  id?: string;
  label?: string;
  labelledBy?: string;
  totalRequired: number;
}

// TODO: Check if "indeterminate" M3 progress bar type is required
const ProgressBar = ({
  className,
  completed,
  count,
  id,
  label,
  labelledBy,
  totalRequired,
}: IProps) => {
  const percentage = useMemo(
    () => (completed ? 100 : (count / totalRequired) * 100),
    [completed, count, totalRequired],
  );

  return (
    <ProgressBarWrapper className={className} id={id}>
      <ProgressBarLine
        aria-label={label}
        aria-labelledby={labelledBy}
        aria-valuemax={totalRequired}
        aria-valuemin={0}
        aria-valuenow={count}
        percentage={percentage}
        role="progressbar"
      />
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
