import {
  Children,
  cloneElement,
  isValidElement,
  KeyboardEventHandler,
  PropsWithChildren,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";
import KEYBOARD_SHORTCUTS from "vui/constants/keyboardShortcuts";

interface IStyledBarProps {
  $active: number | null;
  $childCount: number;
}

const StyledBar = styled.div<IStyledBarProps>`
  display: flex;
  flex-direction: row;
  height: var(--vui-spacing-12);
  overflow: hidden;
  position: sticky;
  top: 0;
  white-space: nowrap;
  width: 100%;

  &::before,
  &::after {
    bottom: 0;
    content: "";
    height: var(--vui-spacing-0-5);
    position: absolute;
  }

  &::before {
    background-color: rgb(var(--vui-color-surface-primary));
    left: 0;
    width: 100%;
  }

  &::after {
    background-color: rgb(var(--vui-color-inverse-surface));
    left: ${({ $active, $childCount }) =>
      css`calc((100% / ${$childCount}) * ${$active ?? 0})`};
    transition: left 0.2s ease;
    width: ${({ $childCount }) => css`calc(100% / ${$childCount})`};
  }
`;

export interface IProps {
  className?: string;
  id?: string;
}

const enum Direction {
  Backwards,
  Forwards,
}

const TabBar: React.FC<PropsWithChildren<IProps>> = ({
  children,
  className,
  id,
}) => {
  const tabListRef = useRef<HTMLDivElement>(null);
  const tabActiveRef = useRef<HTMLElement>(null);
  const tabFocusRef = useRef<HTMLElement>(null);
  const firstUpdate = useRef(true);

  const activeIndex = useMemo(() => {
    const activeChild = Children.toArray(children).findIndex(
      (child) => isValidElement(child) && child.props.active,
    );

    return activeChild === -1 ? null : activeChild;
  }, [children]);

  const [focusIndex, setFocusIndex] = useState(activeIndex ?? 0);

  const items = Children.map(children, (child, index) => {
    if (!isValidElement(child)) {
      return null;
    }

    const isFocusTarget = focusIndex === index;

    let ref: RefObject<HTMLElement> | undefined;

    if (activeIndex === index) {
      ref = tabActiveRef;
    } else if (isFocusTarget) {
      ref = tabFocusRef;
    }

    return cloneElement(child as ReactElement, {
      ref,
      tabIndex: isFocusTarget ? 0 : -1,
    });
  });

  const findNextFocusableChildIndex = useCallback(
    (direction: Direction = Direction.Forwards) => {
      let nextChildren = Children.toArray(children).slice(focusIndex + 1);

      if (direction === Direction.Backwards) {
        nextChildren = Children.toArray(children)
          .slice(0, focusIndex)
          .reverse();
      }

      const nextChildrenIndex = nextChildren.findIndex((child) =>
        isValidElement(child),
      );

      if (nextChildrenIndex === -1) {
        return focusIndex;
      }

      if (direction === Direction.Backwards) {
        return focusIndex - nextChildrenIndex - 1;
      }

      return focusIndex + nextChildrenIndex + 1;
    },
    [focusIndex, children],
  );

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      switch (event.key) {
        case KEYBOARD_SHORTCUTS.ARROW_LEFT:
          event.preventDefault();
          setFocusIndex(findNextFocusableChildIndex(Direction.Backwards));
          break;
        case KEYBOARD_SHORTCUTS.ARROW_RIGHT:
          event.preventDefault();
          setFocusIndex(findNextFocusableChildIndex(Direction.Forwards));
          break;
      }
    },
    [findNextFocusableChildIndex],
  );

  useEffect(() => {
    const focusTarget = tabFocusRef.current || tabActiveRef.current;

    if (!firstUpdate.current && focusTarget) {
      focusTarget.focus();
    }
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
  }, [focusIndex]);

  return (
    <StyledBar
      $active={activeIndex}
      $childCount={Children.count(children)}
      className={className}
      id={id}
      onKeyDown={handleKeyDown}
      ref={tabListRef}
      role="tablist"
    >
      {items}
    </StyledBar>
  );
};

export default TabBar;
