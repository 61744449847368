import styled from "styled-components";
import BREAKPOINTS from "vui/constants/breakpoints";

export const Layout = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 4.375rem 1fr;
  position: relative;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_SM}) {
    grid-template-columns: 5.625rem 1fr;
  }
`;

export const Image = styled.img`
  aspect-ratio: 1 / 1;
  border-radius: var(--vui-shape-corner-medium);
  object-fit: cover;
  width: 100%;
`;

export const Details = styled.div`
  color: rgb(var(--vui-color-on-surface-primary));
  display: flex;
  flex-direction: column;
`;

export const TagWrapper = styled.div`
  margin-top: var(--vui-spacing-2);
`;

export const ApplyTime = styled.span`
  color: rgb(var(--vui-color-on-surface-primary-variant));
  display: block;
  font: var(--vui-type-label-large-shorthand);
  font-weight: var(--vui-type-weight-semibold);
  letter-spacing: var(--vui-type-label-large-spacing);
  margin-top: var(--vui-spacing-1);
`;

export const Payment = styled.span`
  display: block;
  font: var(--vui-type-title-small-shorthand);
  letter-spacing: var(--vui-type-title-small-spacing);
  margin-top: var(--vui-spacing-2);
`;
