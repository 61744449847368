import textualize from "creators/utils/textualize";
import styled from "styled-components";
import { FilledButton } from "vui/components/Button";
import Heading from "vui/components/Heading";
import BREAKPOINTS from "vui/constants/breakpoints";

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--vui-spacing-4);
  height: 100%;
  justify-content: center;
  min-height: calc(100vh - var(--vui-spacing-16));
  width: 100%;

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_LG}) {
    min-height: unset;
  }
`;

const BriefTeaserNotFound: React.FC = () => {
  return (
    <Container>
      <Heading appearance="display" level="1">
        {textualize("vuiError.teaser.notFound.title")}
      </Heading>
      <span>{textualize("vuiError.teaser.notFound.description")}</span>
      <FilledButton onClick={() => window.location.reload()}>
        {textualize("vuiError.teaser.notFound.cta")}
      </FilledButton>
    </Container>
  );
};

export default BriefTeaserNotFound;
