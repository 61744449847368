// TODO: Replace with VUI VideoPlayer
import OnboardingVideoPlayer from "creators/components/OnboardingVideoPlayer";
import ANALYTICS from "creators/constants/analytics";
import IDS from "creators/constants/ids";
import { ONBOARDING_INDICES, TOTAL_STEPS } from "creators/constants/onboarding";
import VIDEOS from "creators/constants/videos";
import { getStepForCreator } from "creators/hooks/useOnboardingProgressAnalytics/useOnboardingProgressAnalytics";
import { CreatorSkill } from "creators/types/graphql";
import { IFormValues } from "creators/types/onboarding";
import textualize from "creators/utils/textualize";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import styled from "styled-components";
import Heading from "vui/components/Heading";
import ProgressBar from "vui/components/ProgressBar";
import SwitchInput from "vui/components/SwitchInput";
import {
  Next,
  ResponsiveLayout,
  SkillsError,
  SkillsFieldset,
  StyledHeading,
  VideoContainer,
} from "../styles";

export interface IProps {
  creatorExternalID: string;
  initialValues: IFormValues;
  onNext: () => void;
  onSubmit: (values: IFormValues) => Promise<void>;
}

const SelectAllThatApply = styled(Heading)`
  margin-bottom: var(--vui-spacing-8);
`;

const SkillsForm: React.FC<React.PropsWithChildren<IProps>> = ({
  creatorExternalID,
  initialValues,
  onNext,
  onSubmit,
}) => {
  const [videoInteraction, setVideoInteraction] = useState(false);

  const {
    control,
    formState: { errors, isValid, isSubmitting },
    handleSubmit,
    trigger,
  } = useForm<IFormValues>({
    defaultValues: initialValues,
    mode: "onBlur",
  });

  const onFormSubmit = useCallback(
    async (values: IFormValues) => {
      if (
        JSON.stringify(initialValues.skills) === JSON.stringify(values.skills)
      ) {
        onNext();
      } else {
        try {
          await onSubmit(values);

          if (getStepForCreator(creatorExternalID) === 0) {
            fireAnalyticsEvent(
              ANALYTICS.CATEGORIES.ONBOARDING,
              ANALYTICS.EVENT.ONBOARDING_SKILLS,
              {
                briefTypeCgc: values.skills?.includes(CreatorSkill.LiveAction),
                briefTypeRemix: values.skills?.includes(
                  CreatorSkill.MotionGraphics,
                ),
                videoInteraction,
              },
            );
          }
        } catch {
          // Handled in OnboardingSteps
        }
      }
    },
    [
      creatorExternalID,
      initialValues.skills,
      onNext,
      onSubmit,
      videoInteraction,
    ],
  );

  return (
    <ResponsiveLayout>
      <div>
        <ProgressBar
          completed={false}
          count={ONBOARDING_INDICES.SKILLS}
          id={IDS.ONBOARDING.PROGRESS}
          label={textualize("onboarding.progress") as string}
          totalRequired={TOTAL_STEPS}
        />

        <StyledHeading appearance="medium" level="2">
          {textualize("onboarding.skills.heading")}
        </StyledHeading>

        <form
          id={IDS.ONBOARDING.SKILLS.ID}
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <SelectAllThatApply appearance="small" level="3">
            {textualize("onboarding.skills.select")}
          </SelectAllThatApply>

          <SkillsFieldset>
            <Controller
              control={control}
              name="skills"
              render={({ field }) => (
                <>
                  <SwitchInput
                    checked={field.value?.includes(CreatorSkill.MotionGraphics)}
                    hasError={!!errors.skills}
                    id={IDS.SKILLS.REMIX}
                    label={textualize("skills.remix.label")}
                    onChange={(e) => {
                      const newValue = (e.target as HTMLInputElement).checked
                        ? [...field.value!, CreatorSkill.MotionGraphics]
                        : field.value?.filter(
                            (skill) => skill !== CreatorSkill.MotionGraphics,
                          );
                      field.onChange(newValue);
                      trigger(field.name);
                    }}
                    supportingText={textualize("skills.remix.description")}
                  />

                  <SwitchInput
                    checked={field.value?.includes(CreatorSkill.LiveAction)}
                    hasError={!!errors.skills}
                    id={IDS.SKILLS.CGC}
                    label={textualize("skills.cgc.label")}
                    onChange={(e) => {
                      const newValue = (e.target as HTMLInputElement).checked
                        ? [...field.value!, CreatorSkill.LiveAction]
                        : field.value?.filter(
                            (skill) => skill !== CreatorSkill.LiveAction,
                          );
                      field.onChange(newValue);
                      trigger(field.name);
                    }}
                    supportingText={textualize("skills.cgc.description")}
                  />

                  {errors.skills && (
                    <SkillsError>{errors.skills.message}</SkillsError>
                  )}
                </>
              )}
              rules={{
                required: textualize("onboarding.skills.error") as string,
              }}
            />
          </SkillsFieldset>

          <Next
            disabled={isSubmitting || !isValid}
            id={IDS.ONBOARDING.NEXT}
            type="submit"
          >
            {textualize("onboarding.next")}
          </Next>
        </form>
      </div>
      <VideoContainer>
        <OnboardingVideoPlayer
          captions={VIDEOS.SKILLS.CAPTIONS}
          id={IDS.ONBOARDING.SKILLS.VIDEO}
          onVideoInteraction={() => setVideoInteraction(true)}
          src={VIDEOS.SKILLS.SOURCE}
        />
      </VideoContainer>
    </ResponsiveLayout>
  );
};

export default SkillsForm;
