import HorizontalNav from "creators/components/HorizontalNav";
import DIMENSIONS from "creators/constants/dimensions";
import {
  ScreenSize,
  ViewportContext,
} from "creators/providers/ViewportProvider";
import { INavLink } from "creators/types/navLinks";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import BREAKPOINTS from "vui/constants/breakpoints";
import useStickyNav from "vui/hooks/useStickyNav";
import {
  BodyContainer,
  BodyContent,
  SideContent,
  StyledPageContainer,
  TopSection,
} from "./styles";

export const UnauthenticatedPageContainer = styled.div`
  padding: var(--vui-spacing-8) var(--vui-spacing-4);

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    padding: var(--vui-spacing-16) 0;
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    padding: var(--vui-spacing-16) 0;
  }
`;

// Used for pages that should take up the full width of the screen
export const PageContainerFull = styled.main`
  margin: 0 auto;
  padding: 0 var(--vui-spacing-4);
  position: relative;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_SM}) {
    padding: 0 var(--vui-spacing-8);
  }

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_MD}) {
    margin: 0 var(--vui-spacing-4);
    margin-left: calc(var(--vui-spacing-20) + var(--vui-spacing-4));
    max-width: 100rem;
    padding: var(--vui-spacing-4) 0 0;
  }
`;

interface ITopSectionOptions {
  id: string;
  label: string;
  links: INavLink[];
}

interface IProps {
  bannerContent?: JSX.Element | null;
  className?: string;
  id?: string;
  sideContent?: JSX.Element;
  topSection?: ITopSectionOptions;
}

// Used for pages that should be contained to a max-width
const PageContainer: React.FC<React.PropsWithChildren<IProps>> = ({
  bannerContent,
  children,
  className,
  id,
  sideContent,
  topSection,
}) => {
  const size = useContext(ViewportContext);

  const topSectRef = useRef<HTMLDivElement>(null);

  const [topSectSize, setTopSectSize] = useState(0);

  const { isHidden, isSticky } = useStickyNav(topSectRef.current!, {
    offsetHidden: topSectSize,
    offsetSticky: size === ScreenSize.Mobile ? 0 : 16,
  });

  useEffect(() => {
    if (topSectRef.current) {
      setTopSectSize(topSectRef.current.getBoundingClientRect().bottom);
    }
  }, []);

  return (
    <StyledPageContainer className={className} id={id}>
      {/* TOP NAV */}
      {(topSection || bannerContent) && (
        <TopSection $hidden={isHidden} ref={topSectRef}>
          {bannerContent}
          {topSection && (
            <HorizontalNav
              id={topSection.id}
              label={topSection.label}
              links={topSection.links}
              showShadow={isSticky}
            />
          )}
        </TopSection>
      )}

      <BodyContainer $withNav={!!topSection}>
        {/* SIDE NAV */}
        {sideContent && (
          <SideContent $topPos={topSectSize}>{sideContent}</SideContent>
        )}
        <BodyContent $withSide={!!sideContent}>{children}</BodyContent>
      </BodyContainer>
    </StyledPageContainer>
  );
};

export default PageContainer;
