import LoadingDelayed from "creators/components/LoadingDelayed";
import LoggedOutAnimation from "creators/components/LoggedOutAnimation";
import DIMENSIONS from "creators/constants/dimensions";
import {
  ScreenSize,
  ViewportContext,
} from "creators/providers/ViewportProvider";
import unauthenticatedRoutes from "creators/routes/unauthenticated";
import { Suspense, useContext } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100vh;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Container = styled.div`
  margin: 0 auto;
  max-width: 24rem;
`;

export const Unauthenticated = () => {
  const size = useContext(ViewportContext);
  const location = useLocation();

  return (
    <>
      <Grid>
        <main>
          <Container>
            <Suspense fallback={<LoadingDelayed />}>
              <Switch>
                {unauthenticatedRoutes.map(({ component, exact, path }, i) => (
                  <Route
                    component={component}
                    exact={exact}
                    key={i}
                    path={path}
                  />
                ))}

                {/* Redirect paths from previous Creators app to their new homes */}
                <Redirect from="/login/new-user" to="/register" />

                <Redirect
                  to={{
                    pathname: "/login",
                    state: {
                      referrerUri:
                        location && location.pathname + location.search,
                    },
                  }}
                />
              </Switch>
            </Suspense>
          </Container>
        </main>

        {size === ScreenSize.Desktop && <LoggedOutAnimation />}
      </Grid>
    </>
  );
};

export default Unauthenticated;
