// TODO: Replace with VUI VideoPlayer
import OnboardingVideoPlayer from "creators/components/OnboardingVideoPlayer";
import EXTERNAL_LINKS from "creators/constants/externalLinks";
import IDS from "creators/constants/ids";
import { ONBOARDING_INDICES, TOTAL_STEPS } from "creators/constants/onboarding";
import VIDEOS from "creators/constants/videos";
import { IFormValues } from "creators/types/onboarding";
import textualize from "creators/utils/textualize";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Redirect } from "react-router-dom";
import ProgressBar from "vui/components/ProgressBar";
import TextInput from "vui/components/TextInput";
import {
  Back,
  Next as LatestBriefs,
  ResponsiveLayout,
  StyledHeading,
  VideoContainer,
} from "../styles";

export interface IProps {
  initialValues: IFormValues;
  onBack: () => void;
  onSubmit: (values: IFormValues) => Promise<void>;
}

const PayeeNameForm: React.FC<React.PropsWithChildren<IProps>> = ({
  initialValues,
  onBack,
  onSubmit,
}) => {
  const [submitted, setSubmitted] = useState(false);

  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = useForm<IFormValues>({
    defaultValues: initialValues,
    mode: "onBlur",
  });

  const navigateToLatestBriefs = useCallback(
    async (values: IFormValues) => {
      if (initialValues.payeeName === values.payeeName) {
        setSubmitted(true);
        return;
      }

      try {
        await onSubmit(values);
        setSubmitted(true);
      } catch {
        setSubmitted(false);
      }
    },
    [initialValues, onSubmit],
  );

  if (submitted) {
    return <Redirect to="/" />;
  }

  const fullNameSupportingText = textualize(
    "vuiSettings.payment.fullName.help.text",
    {
      link: (
        <a
          href={EXTERNAL_LINKS.HOW_PAYMENTS_WORK}
          rel="noopener noreferrer"
          target="_blank"
        >
          {textualize("vuiSettings.payment.fullName.help.link")}
        </a>
      ),
    },
  );

  return (
    <ResponsiveLayout>
      <div>
        <ProgressBar
          completed={true}
          count={ONBOARDING_INDICES.PAYEE_NAME}
          id={IDS.ONBOARDING.PROGRESS}
          label={textualize("onboarding.progress") as string}
          totalRequired={TOTAL_STEPS}
        />

        <StyledHeading appearance="medium" level="2">
          {textualize("onboarding.payeeName.heading")}
        </StyledHeading>

        <form
          id={IDS.ONBOARDING.PAYEE_NAME.ID}
          onSubmit={handleSubmit(navigateToLatestBriefs)}
        >
          <Controller
            control={control}
            name="payeeName"
            render={({ field }) => (
              <TextInput
                {...field}
                hasError={!!errors.payeeName}
                id={IDS.ONBOARDING.PAYEE_NAME.INPUT}
                label={textualize("onboarding.payeeName.label")}
                required={true}
                supportingText={
                  errors.payeeName?.message || fullNameSupportingText
                }
              />
            )}
            rules={{
              required: textualize("onboarding.payeeName.error") as string,
            }}
          />

          <Back id={IDS.ONBOARDING.BACK} onClick={onBack}>
            {textualize("onboarding.back")}
          </Back>

          <LatestBriefs
            disabled={isSubmitting || !isValid}
            id={IDS.ONBOARDING.LATEST_BRIEFS}
            type="submit"
          >
            {textualize("onboarding.payeeName.latestBriefs")}
          </LatestBriefs>
        </form>
      </div>
      <VideoContainer>
        <OnboardingVideoPlayer
          captions={VIDEOS.PAYEE_NAME.CAPTIONS}
          id={IDS.ONBOARDING.PAYEE_NAME.VIDEO}
          src={VIDEOS.PAYEE_NAME.SOURCE}
        />
      </VideoContainer>
    </ResponsiveLayout>
  );
};

export default PayeeNameForm;
