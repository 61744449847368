import styled from "styled-components";
import Icon from "vui/components/Icon";

export const Container = styled.div`
  align-items: center;
  display: flex;
  font-weight: var(--vui-type-weight-semibold);
  height: 100%;
  gap: var(--vui-spacing-2);
  justify-content: center;
  padding: 0 var(--vui-spacing-6);
  text-align: center;
  width: 100%;
  white-space: nowrap;
`;

export const ItemIcon = styled(Icon)`
  height: var(--vui-spacing-6);
  width: var(--vui-spacing-6);
`;
