import {
  IProps as IBlockContainer,
  WideBlockContainer,
} from "creators/components/Brief/BlockContainer/BlockContainer";
import DIMENSIONS from "creators/constants/dimensions";
import flattenDeep from "lodash/flattenDeep";
import { useCallback, useMemo, useState } from "react";
import { Block, BlockProperties } from "shared/BlockBuilder/global";
import styled from "styled-components";
import BREAKPOINTS from "vui/constants/breakpoints";
import VideoGroup from "./components/VideoGroup";

export interface IProps {
  block: Block;
  className?: string;
  error?: string;
  handleChange?: (value: { properties: BlockProperties }) => void;
  id: string;
}

const Container = styled(WideBlockContainer)<IBlockContainer>`
  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    max-width: unset;
  }

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_LG}) {
    max-width: 45.75rem;
  }
`;

function BriefVideo({ block, handleChange, id, ...rest }: IProps) {
  const value = useMemo(
    () =>
      Array.isArray(block.properties.value)
        ? flattenDeep(block.properties.value) // TODO: Replace with Array.flat once lib compiler updated
        : [block.properties.value].filter((val) => val !== undefined),
    [block.properties.value],
  );

  const ratios = useMemo(
    () =>
      Array.isArray(block.properties.ratios)
        ? flattenDeep(block.properties.ratios) // TODO: Replace with Array.flat once lib compiler updated
        : ([block.properties.ratios].filter(
            (val) => val !== undefined,
          ) as string[]),
    [block.properties.ratios],
  );

  const [editing, setEditing] = useState<boolean>(
    !block.properties.value ? true : false,
  );
  const editable = !!handleChange;

  const addVideo = useCallback(
    (path: string, ratio: string) => {
      handleChange!({
        properties: {
          ratios: [...ratios, ratio],
          value: [...value, path],
        },
      });
    },
    [handleChange, ratios, value],
  );

  const removeVideo = useCallback(
    (uriIndex: number) => {
      const newRatios = ratios.filter((_, i) => i !== uriIndex);
      const newValue = value.filter((_, i) => i !== uriIndex);

      handleChange!({
        properties: {
          ratios: newRatios,
          value: newValue,
        },
      });
    },
    [handleChange, ratios, value],
  );

  const reorderVideos = useCallback(
    (originalIndex: number, newIndex: number) => {
      const newRatios = Array.from(ratios);
      const [removedRatio] = newRatios.splice(originalIndex, 1);
      newRatios.splice(newIndex, 0, removedRatio);

      const newValue = Array.from(value);
      const [removedValue] = newValue.splice(originalIndex, 1);
      newValue.splice(newIndex, 0, removedValue);

      handleChange!({
        properties: {
          ratios: newRatios as any,
          value: newValue,
        },
      });
    },
    [handleChange, ratios, value],
  );

  return (
    <Container {...rest} editable={editable} editing={editing} id={id}>
      <VideoGroup
        addVideo={addVideo}
        editable={editable}
        editing={editing}
        id={id}
        ratios={ratios}
        removeVideo={removeVideo}
        reorderVideos={reorderVideos}
        setEditing={setEditing}
        uris={value}
      />
    </Container>
  );
}

export default BriefVideo;
