import FONTS from "creators/constants/fonts";
import { h1Styles, h2Styles, h3Styles } from "creators/styling/Heading/Heading";
import linkStyles from "creators/styling/Link";
import smallStyles from "creators/styling/SmallText";
import { css } from "styled-components";
import VuiGlobalStyle from "vui/styles";

export const innerStyles = css`
  h1 {
    ${h1Styles}
  }

  h2 {
    ${h2Styles}
  }

  h3 {
    ${h3Styles}
  }

  a {
    ${linkStyles}
  }

  strong {
    font-weight: ${FONTS.WEIGHT.BOLD};
  }

  em {
    font-style: italic;
  }

  small {
    ${smallStyles}
  }
`;

const GlobalStyle: React.FC = () => {
  return <VuiGlobalStyle />;
};

export default GlobalStyle;
