import { useQuery } from "@apollo/client";
import ModalWrapper from "creators/components/Modal/ModalWrapper";
import IDS from "creators/constants/ids";
import { GET_ACTIVE_BRIEF } from "creators/queries/GetActiveBrief";
import {
  GetActiveBriefQuery as IGetActiveBrief,
  SubmissionState,
} from "creators/types/graphql";
import textualize from "creators/utils/textualize";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FilledButton } from "vui/components/Button";
import Heading from "vui/components/Heading";
import { Cta, StyledStandardModal } from "./styles";

// TODO: Modal needs to be created as a vui component
const LiveBriefInvite: React.FC = () => {
  const [displayModal, setDisplayModal] = useState(true);

  const { data } = useQuery<IGetActiveBrief>(GET_ACTIVE_BRIEF);
  const submission = data?.viewerSubmissions.edges?.[0]?.node;

  const handleClose = () => setDisplayModal(false);

  if (submission?.state === SubmissionState.Invited && displayModal) {
    return (
      <ModalWrapper id={IDS.MODALS.LIVE_BRIEF_INVITE.ID} onClose={handleClose}>
        <StyledStandardModal>
          <Heading appearance="medium" level="1">
            {textualize("vuiLiveBriefs.liveBriefInvite.title")}
          </Heading>
          <div>
            {textualize("vuiLiveBriefs.liveBriefInvite.message", {
              title: submission.brief.title,
            })}
          </div>
          <Cta>
            <FilledButton
              forwardedAs={Link}
              id={IDS.MODALS.LIVE_BRIEF_INVITE.GO_TO_BRIEF_BUTTON}
              to="/active-brief"
            >
              {textualize("vuiLiveBriefs.liveBriefInvite.link")}
            </FilledButton>
          </Cta>
        </StyledStandardModal>
      </ModalWrapper>
    );
  }

  return null;
};

export default LiveBriefInvite;
