import { FC, PropsWithChildren } from "react";
import { Container, ItemIcon } from "./styles";

export interface IProps {
  className?: string;
  icon?: FC;
  id?: string;
}

export const HorizontalSubNavItem = ({
  children,
  className,
  icon,
  id,
}: PropsWithChildren<IProps>) => {
  return (
    <Container className={className} id={id}>
      {icon && <ItemIcon component={icon} />}
      {children}
    </Container>
  );
};

export default HorizontalSubNavItem;
