import { PageContainerFull } from "creators/components/PageContainer";
import DIMENSIONS from "creators/constants/dimensions";
import styled from "styled-components";
import Heading from "vui/components/Heading";
import TabBar from "vui/components/TabBar";
import BREAKPOINTS from "vui/constants/breakpoints";

export const StyledPageContainer = styled(PageContainerFull)`
  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    padding-bottom: 0;
  }
`;

export const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--vui-spacing-4);

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_MD}) {
    display: grid;
    flex-direction: unset;
    gap: var(--vui-spacing-8);
    grid-template-columns: 22rem 1fr;
    height: calc(100dvh - var(--vui-spacing-4));
    overflow: hidden;
  }

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_LG}) {
    grid-template-columns: 31rem 1fr;
  }
`;

export const BriefsColumn = styled.section`
  display: flex;
  flex-direction: column;
  gap: var(--vui-spacing-4);
  overflow: hidden;
  position: relative;
`;

export const StyledTabBar = styled(TabBar)`
  box-sizing: content-box;
  flex: 0 0 var(--vui-spacing-12);
`;

export const Placeholder = styled.div`
  @media (min-width: ${BREAKPOINTS.BREAKPOINT_MD}) {
    padding-top: var(--vui-spacing-4);
  }
`;

export const LandingWrapper = styled.section`
  display: flex;
  justify-content: flex-start;
  margin-top: var(--vui-spacing-4);
  width: 100%;

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_MD}) {
    grid-column: 2;
    grid-row: 1;
    justify-content: center;
  }
`;

export const LandingView = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_MD}) {
    align-items: center;
    height: 100vh;
    justify-content: center;
    position: sticky;
    top: 0;
  }
`;

export const LandingTitle = styled(Heading)`
  display: block;
  flex: 0 1 auto;
`;

export const LandingPrompt = styled.span`
  color: rgb(var(--vui-color-on-surface-primary-variant));
  font: var(--vui-type-title-medium-shorthand);
  letter-spacing: var(--vui-type-title-medium-spacing);
`;
