import styled, { css } from "styled-components";
import BREAKPOINTS from "vui/constants/breakpoints";

export const StyledPageContainer = styled.main`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: var(--vui-spacing-2) var(--vui-spacing-4) var(--vui-spacing-12);
  position: relative;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_SM}) {
    padding: var(--vui-spacing-2) var(--vui-spacing-8) var(--vui-spacing-12);
  }

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_MD}) {
    margin-left: calc(var(--vui-spacing-20) + var(--vui-spacing-4));
    padding: var(--vui-spacing-4);
    padding-bottom: var(--vui-spacing-12);
  }
`;

export const BodyContainer = styled.div<{ $withNav?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  ${({ $withNav }) =>
    !$withNav &&
    css`
      margin-top: var(--vui-spacing-12);
    `};

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_LG}) {
    align-items: unset;
    flex-direction: row-reverse;
    justify-content: center;
  }
`;

export const TopSection = styled.div<{ $hidden?: boolean }>`
  background: rgb(var(--vui-color-background));
  display: flex;
  flex-direction: column;
  gap: var(--vui-spacing-4);
  margin: 0 auto var(--vui-spacing-12);
  max-width: 100rem;
  opacity: 1;
  padding: var(--vui-spacing-4) 0 0;
  position: sticky;
  top: var(--vui-spacing-16);
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  z-index: 1;

  @media (max-width: calc(${BREAKPOINTS.BREAKPOINT_MD} - 1px)) {
    ${({ $hidden }) =>
      $hidden &&
      css`
        opacity: 0;
        pointer-events: none;
      `}
  }

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_MD}) {
    position: sticky;
    top: 0;
  }
`;

export const BodyContent = styled.div<{
  $withSide?: boolean;
}>`
  margin-left: 0;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_LG}) {
    ${({ $withSide }) =>
      $withSide &&
      "margin-left: calc(var(--vui-spacing-52) - var(--vui-spacing-1))"};
    max-width: 58.5rem;
  }
`;

export const SideContent = styled.div<{ $topPos?: number }>`
  display: none;

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_LG}) {
    display: block;
    grid-column: 3 / 3;
    height: auto;
    margin: 0 var(--vui-spacing-6);
    margin-bottom: auto;
    position: sticky;
    top: ${({ $topPos }) =>
      $topPos
        ? css`
            ${$topPos}px
          `
        : css`var(--vui-spacing-8)`};
    width: calc(var(--vui-spacing-36) + var(--vui-spacing-3));
  }
`;
