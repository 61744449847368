import styled from "styled-components";

export const ProgressBarWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  position: relative;
  width: 100%;
`;

export const ProgressBarLine = styled.div<{ percentage: number }>`
  background-color: rgb(var(--vui-color-surface-tertiary));
  border-radius: var(--vui-spacing-4);
  display: block;
  height: var(--vui-spacing-1);
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 2;

  &::after {
    background-color: rgb(var(--vui-color-on-surface-tertiary));
    border-radius: 1rem;
    box-shadow: 0.125rem 0 0 0.125rem rgb(var(--vui-color-surface-primary));
    content: "";
    height: 100%;
    position: absolute;
    transition: width 500ms ease-in-out;
    width: ${(props) => (props.percentage > 100 ? "100" : props.percentage)}%;
  }

  &::before {
    background-color: rgb(var(--vui-color-on-surface-tertiary));
    border-radius: 1rem;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    width: var(--vui-spacing-1);
  }
`;
