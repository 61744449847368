const KEYBOARD_SHORTCUTS = {
  ARROW_DOWN: "ArrowDown",
  ARROW_LEFT: "ArrowLeft",
  ARROW_RIGHT: "ArrowRight",
  ARROW_UP: "ArrowUp",
  ENTER: "Enter",
  ESCAPE: "Escape",
  KEY_Y: "y",
  KEY_Z: "z",
};

export default KEYBOARD_SHORTCUTS;
