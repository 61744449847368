import styled from "styled-components";

export const Container = styled.ul`
  background-color: rgb(var(--vui-color-surface-secondary));
  border-radius: var(--vui-shape-corner-extra-small);
  color: rgb(var(--vui-color-on-surface-secondary));
  list-style: none;
  margin: 0;
  max-height: calc(10 * var(--vui-spacing-12));
  overflow: auto;
  padding: var(--vui-spacing-2) 0;
`;
