import styled from "styled-components";
import { OutlinedButton } from "vui/components/Button";
import Icon from "vui/components/Icon";

export const BriefListGrid = styled.ul`
  display: flex;
  flex-direction: column;
  gap: var(--vui-spacing-2);
  overflow: auto;
  padding-bottom: var(--vui-spacing-12);
`;

export const LoadMoreButton = styled(OutlinedButton)`
  flex: 0 0 auto;
  justify-content: center;
`;

export const LoadMoreWrapper = styled.li`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: var(--vui-spacing-4);
  margin: var(--vui-spacing-4) auto 0;
`;

export const NoResults = styled.div`
  align-items: center;
  color: rgb(var(--vui-color-on-background));
  display: flex;
  flex-direction: column;
  gap: var(--vui-spacing-4);
  margin-top: var(--vui-spacing-4);
  text-align: center;
`;

export const NoResultsIcon = styled(Icon)`
  color: rgb(var(--vui-color-mono-5));
  height: var(--vui-spacing-6);
  width: var(--vui-spacing-6);
`;
