import styled from "styled-components";
import Heading from "vui/components/Heading";
import Icon from "vui/components/Icon";

export const Container = styled.section`
  margin-top: var(--vui-spacing-8);
`;

export const StyledHeading = styled(Heading)`
  margin-bottom: var(--vui-spacing-2);
`;

export const List = styled.dl`
  display: flex;
  flex-direction: column;
  gap: var(--vui-spacing-2);
`;

export const Term = styled.dt`
  display: inline-block;
  font-weight: var(--vui-type-weight-semibold);

  &::after {
    content: ":\\00a0";
  }
`;

export const Details = styled.dd`
  display: inline-block;
`;

export const StyledIcon = styled(Icon)`
  margin-left: var(--vui-spacing-1);
  vertical-align: sub;
`;
