import textualize from "creators/utils/textualize";
import { useEffect } from "react";
import styled, { css } from "styled-components";

export interface IProps {
  className?: string;
  id?: string;
  onClick?: () => void;
  style?: {
    // React spring animation styles
    opacity: string;
  };
}

const overlayStyles = css`
  appearance: none;
  background: rgba(var(--v-color-black-rgb), 0.25);
  border: none;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
  will-change: opacity;
  z-index: 2;
`;

const ClickableBackdrop = styled.button`
  ${overlayStyles}
  background: rgba(var(--vui-color-inverse-surface-light), 0.33);
`;

const StaticBackdrop = styled.div`
  ${overlayStyles}
  background: rgba(var(--vui-color-inverse-surface-light), 0.33);
`;

export const OverlayBackdrop: React.FC<React.PropsWithChildren<IProps>> = ({
  className,
  id,
  onClick,
  style,
}) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.overscrollBehaviorY = "none";

    return () => {
      document.body.style.removeProperty("overflow");
      document.body.style.removeProperty("overscroll-behavior-y");
    };
  }, []);

  return onClick ? (
    <ClickableBackdrop
      aria-label={textualize("common.close") as string}
      className={className}
      id={id}
      onClick={onClick}
      style={style}
      tabIndex={-1}
    />
  ) : (
    <StaticBackdrop className={className} id={id} style={style} />
  );
};

export default OverlayBackdrop;
