import ANIMATIONS from "creators/constants/animations";
import ErrorPage from "creators/pages/Error";
import textualize from "creators/utils/textualize";
import { Helmet } from "react-helmet";
import useLottieAnimation from "shared/hooks/useLottieAnimation";
import styled from "styled-components";
import Heading from "vui/components/Heading";

const AnimationContainer = styled.div`
  margin: var(--vui-spacing-8) 0;
  max-width: 40vh;
`;

const NotFound: React.FC = () => {
  const { AnimationComponent } = useLottieAnimation({
    animation: ANIMATIONS.NOT_FOUND,
  });

  return (
    <ErrorPage>
      <Helmet title={textualize("vuiNotFound.heading") as string} />

      <AnimationContainer>{AnimationComponent}</AnimationContainer>

      <Heading appearance="large" level="1">
        {textualize("vuiNotFound.heading")}
      </Heading>
      <span>{textualize("vuiNotFound.body")}</span>
    </ErrorPage>
  );
};

export default NotFound;
