import styled from "styled-components";
import Sentiment from "vui/constants/sentiment";

const getSentimentColor = (sentiment: Sentiment) =>
  `var(--vui-color-sentiment-${sentiment}-variant)`;

const getSentimentTextColor = (sentiment: Sentiment) =>
  `var(--vui-color-on-sentiment-${sentiment}-variant)`;

export const Tag = styled.div<{ sentiment?: Sentiment }>`
  align-items: center;
  background-color: rgb(
    ${({ sentiment }) =>
      sentiment
        ? getSentimentColor(sentiment)
        : "var(--vui-color-surface-secondary)"}
  );
  border-radius: var(--vui-shape-corner-small);
  color: rgb(
    ${({ sentiment }) =>
      sentiment
        ? getSentimentTextColor(sentiment)
        : "var(--vui-color-on-surface-secondary))"}
  );
  display: inline-flex;
  gap: var(--vui-spacing-1);
  padding: var(--vui-spacing-1) var(--vui-spacing-2);
  text-align: center;
  vertical-align: text-bottom;
`;
