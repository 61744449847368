import { FloatingPortal } from "@floating-ui/react";
import { FC, HTMLAttributes, ReactNode } from "react";
import useTooltip, { IOptions } from "vui/hooks/useTooltip";
import {
  Actions,
  RichTooltipContainer,
  Title,
  TooltipContainer,
} from "./styles";

export interface IProps extends HTMLAttributes<HTMLDivElement> {
  hideTooltip?: boolean;
  supportingText: string | ReactNode;
  role?: IOptions["role"];
}

export interface IRichTooltipProps extends IProps {
  actions?: ReactNode;
  title: string;
}

export const Tooltip: FC<IProps> = ({
  children,
  hideTooltip,
  role,
  supportingText,
  ...rest
}) => {
  const { floatingProps, isOpen, trigger } = useTooltip(children, {
    role,
    triggerProps: rest,
  });

  return (
    <>
      {trigger}

      {isOpen && (
        <FloatingPortal>
          <TooltipContainer {...floatingProps}>
            {supportingText}
          </TooltipContainer>
        </FloatingPortal>
      )}
    </>
  );
};

export const RichTooltip: FC<IRichTooltipProps> = ({
  actions,
  children,
  supportingText,
  title,
  ...rest
}) => {
  const { floatingProps, isOpen, trigger } = useTooltip(children, {
    role: "tooltip",
    triggerProps: rest,
  });

  return (
    <>
      {trigger}

      {isOpen && (
        <FloatingPortal>
          <RichTooltipContainer {...floatingProps}>
            <Title appearance="body" level="3">
              {title}
            </Title>

            {supportingText}

            {actions && <Actions>{actions}</Actions>}
          </RichTooltipContainer>
        </FloatingPortal>
      )}
    </>
  );
};

export default Tooltip;
