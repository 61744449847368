import styled, { css } from "styled-components";

export const DIAMETER = 100;
export const STROKE_WIDTH = 4;
export const RADIUS = (DIAMETER - STROKE_WIDTH) / 2;
export const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

export const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export const pathStyles = css`
  fill: none;
  stroke: rgb(var(--vui-color-on-surface-tertiary));
  stroke-width: ${STROKE_WIDTH};
`;

export const Circle = styled.circle`
  ${pathStyles}
  transition: stroke-dashoffset 300ms;
`;

export const CircleOutline = styled.circle`
  ${pathStyles}
  stroke: rgb(var(--vui-color-surface-primary));
  stroke-width: ${STROKE_WIDTH * 2};
  transition: stroke-dashoffset 300ms;
`;

export const BackgroundCircle = styled.circle`
  ${pathStyles}
  stroke: rgb(var(--vui-color-surface-tertiary));
  transition: stroke-dashoffset 300ms;
`;
