import styled, { css } from "styled-components";
import Icon from "vui/components/Icon";

export const InputWrapper = styled.div<{
  hasError: boolean;
  isDisabled: boolean;
}>`
  display: inline-block;
  position: relative;
  width: 100%;

  /* Active indicator */
  ::after {
    background-color: rgb(var(--vui-color-on-surface-secondary));
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    transition:
      background-color 200ms,
      height 200ms;

    ${({ hasError }) =>
      hasError &&
      css`
        background-color: rgb(var(--vui-color-sentiment-negative));
      `};

    ${({ isDisabled }) =>
      isDisabled &&
      css`
        background-color: rgba(var(--vui-color-disabled-on-surface), 0.5);
      `};
  }

  &:has(input:focus),
  &:has(textarea:focus) {
    ::after {
      height: var(--vui-spacing-0-5);

      ${({ hasError }) =>
        hasError &&
        css`
          background-color: rgb(var(--vui-color-sentiment-negative));
        `};
    }
  }

  & + label {
    background-color: rgb(var(--vui-color-surface-secondary));
  }

  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      &:hover label {
        background-color: rgb(var(--vui-color-surface-tertiary));
      }
    `};
`;

export const IconButton = styled.button`
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  height: var(--vui-spacing-6);
  line-height: var(--vui-type-line-height-sm);
  padding: 0;
  width: var(--vui-spacing-6);
`;

export const Label = styled.label<{
  hasLeadingIcon: boolean;
  hasTrailingElement: boolean;
  hasValue: boolean;
  isDisabled: boolean;
}>`
  color: rgb(var(--vui-color-on-surface-secondary));
  font: var(--vui-type-label-small-shorthand);
  font-size: var(--vui-type-label-small-size);
  line-height: var(--vui-type-label-small-line-height);
  padding: var(--vui-spacing-3) 0 var(--vui-spacing-1);
  position: absolute;
  top: 0;
  width: ${({ hasLeadingIcon }) =>
    hasLeadingIcon
      ? css`calc(100% - (var(--vui-spacing-4) + var(--vui-spacing-3) + var(--vui-spacing-6) + var(--vui-spacing-4)))`
      : css`calc(100% - var(--vui-spacing-8));`};

  transition:
    translate 200ms,
    font-size 200ms,
    line-height 200ms;
  translate: ${({ hasLeadingIcon }) =>
    hasLeadingIcon
      ? css`calc(var(--vui-spacing-3) + var(--vui-spacing-6) + var(--vui-spacing-4))`
      : css`var(--vui-spacing-4)`};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: rgba(var(--vui-color-disabled-on-surface), 0.5);
    `};

  ${({ hasLeadingIcon, hasValue }) =>
    !hasValue &&
    css`
      :has(+ input:not(:focus)),
      :has(+ textarea:not(:focus)) {
        font-size: var(--vui-type-label-large-size);
        line-height: var(--vui-type-label-large-line-height);
        pointer-events: none;
        translate: ${hasLeadingIcon
          ? css`calc(var(--vui-spacing-3) + var(--vui-spacing-6) + var(--vui-spacing-4)) calc((var(--vui-spacing-10) - var(--vui-type-label-large-size)) / 2)`
          : css`var(--vui-spacing-4) calc((var(--vui-spacing-10) - var(--vui-type-label-large-size)) / 2)`};
      }
    `};
`;

export const Input = styled.input<{
  hasLeadingIcon: boolean;
  hasLabel: boolean;
  hasTrailingElement: boolean;
  isDisabled: boolean;
  $multiline: boolean;
}>`
  appearance: none;
  background-color: rgb(var(--vui-color-surface-secondary));
  border: 0;
  border-radius: var(--vui-shape-corner-extra-small)
    var(--vui-shape-corner-extra-small) 0 0;
  color: rgb(var(--vui-color-on-surface-secondary));
  caret-color: rgb(var(--vui-color-on-surface-secondary));
  display: block;
  height: var(--vui-spacing-16);
  font: var(--vui-type-input-shorthand);
  letter-spacing: var(--vui-type-input-spacing);
  outline: none;
  padding: var(--vui-spacing-6) var(--vui-spacing-4) var(--vui-spacing-3);
  width: 100%;

  ${({ $multiline }) =>
    $multiline &&
    css`
      max-height: 17.5rem; /* Limit max number of rows to ~10 */
      min-height: var(--vui-spacing-16);
      resize: none;
    `}

  ${({ hasLabel }) =>
    !hasLabel &&
    css`
      padding-top: var(--vui-spacing-2);
    `};

  ${({ hasLeadingIcon }) =>
    hasLeadingIcon &&
    css`
      padding-left: calc(
        var(--vui-spacing-3) + var(--vui-spacing-6) + var(--vui-spacing-4)
      );
    `};

  ${({ hasTrailingElement }) =>
    hasTrailingElement &&
    css`
      padding-right: calc(
        var(--vui-spacing-4) + var(--vui-spacing-6) + var(--vui-spacing-3)
      );
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-color: rgb(var(--vui-color-disabled-surface));
      color: rgba(var(--vui-color-disabled-on-surface), 0.5);
      cursor: default;
    `};

  :hover:not(:disabled),
  :has(~ :hover) {
    background-color: rgb(var(--vui-color-surface-tertiary));
  }

  ::-ms-reveal {
    // Remove password reveal icon in IE
    display: none;
  }
`;

export const SupportingContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--vui-spacing-8);
  width: 100%;
`;

export const SupportingText = styled.div<{
  hasError: boolean;
  isDisabled: boolean;
}>`
  color: rgb(var(--vui-color-on-surface-secondary));
  font: var(--vui-type-label-small-shorthand);
  letter-spacing: var(--vui-type-label-small-spacing);
  padding: var(--vui-spacing-1) var(--vui-spacing-4);

  ${({ hasError }) =>
    hasError &&
    css`
      color: rgb(var(--vui-color-sentiment-negative));
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: rgba(var(--vui-color-disabled-on-surface), 0.85);
    `};
`;

export const LeadingIconContainer = styled.div`
  bottom: var(--vui-spacing-5);
  display: flex;
  left: var(--vui-spacing-3);
  position: absolute;

  :hover ~ input {
    /* Hovering over leading icon also triggers input hover background */
    background-color: rgb(var(--vui-color-surface-tertiary));
  }
`;

export const StyledIcon = styled(Icon)<{ isDisabled: boolean }>`
  color: rgb(var(--vui-color-on-surface-secondary));
  height: var(--vui-spacing-6);
  margin: auto;
  width: var(--vui-spacing-6);

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: rgba(var(--vui-color-disabled-on-surface), 0.5);
    `};
`;

export const TrailingElementContainer = styled(LeadingIconContainer)`
  bottom: var(--vui-spacing-3);
  left: auto;
  right: var(--vui-spacing-3);
`;

export const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

export const WordCount = styled.div<{
  hasError?: boolean;
  isDisabled: boolean;
}>`
  color: rgb(var(--vui-color-on-surface-secondary-variant));
  font: var(--vui-type-label-small-shorthand);
  letter-spacing: var(--vui-type-label-small-spacing);
  margin-left: auto;
  padding: var(--vui-spacing-1) var(--vui-spacing-4);

  ${({ hasError }) =>
    hasError &&
    css`
      color: rgb(var(--vui-color-sentiment-negative));
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: rgba(var(--vui-color-disabled-on-surface), 0.5);
    `};
`;
