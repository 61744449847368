import BriefTeaser from "creators/pages/BriefTeaser";
import { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import styled from "styled-components";

export const Container = styled.section`
  background-color: rgb(var(--vui-color-surface-primary));
  border-radius: var(--vui-shape-corner-extra-large);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  grid-column: 2;
  grid-row: 1;
  height: calc(100vh - (var(--vui-spacing-4) * 2));
  margin: 0;
  overflow-y: auto;
  padding: var(--vui-spacing-6);
  width: 100%;
  z-index: 2;
`;

interface IRouteParams {
  id?: string;
  previewHash?: string;
  slug?: string;
}

const AnimatedContainer = animated(Container);

const BriefTeaserContainer: React.FC = () => {
  const [firstChange, setFirstChange] = useState(true);

  const match = useRouteMatch<IRouteParams>({
    exact: true,
    path: ["/brief/:id(\\d+)/:slug?", "/brief/:id(\\d+)/teaser/:previewHash"],
  });

  const transitionData = {
    id: match?.params.id,
    previewHash: match?.params.previewHash,
    slug: match?.params.slug,
  };

  const navMenuTransitions = useTransition(transitionData, {
    enter: { transform: "translateY(0%)" },
    from: {
      transform: "translateY(100%)",
    },
    immediate: !firstChange,
    key: (item: IRouteParams) => item.id,
  });

  useEffect(() => {
    if (match && firstChange) {
      setFirstChange(false);
    }

    if (!match) {
      setFirstChange(true);
    }
  }, [firstChange, match]);

  if (!match) {
    return null;
  }

  return (
    <>
      {navMenuTransitions(
        (props, { id, previewHash, slug }, { key }) =>
          id && (
            <AnimatedContainer key={key} style={props}>
              <BriefTeaser briefID={id} previewHash={previewHash} slug={slug} />
            </AnimatedContainer>
          ),
      )}
    </>
  );
};

export default BriefTeaserContainer;
