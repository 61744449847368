import styled from "styled-components";
import BREAKPOINTS from "vui/constants/breakpoints";

export const Container = styled.div`
  background: rgb(var(--vui-color-surface-secondary));
  border-radius: var(--vui-shape-corner-large) var(--vui-shape-corner-large) 0 0;
  bottom: 0;
  color: rgb(var(--vui-color-on-surface-secondary));
  display: flex;
  flex-direction: column;
  margin: 0 calc(-1 * var(--vui-spacing-4));
  margin-top: auto;
  padding-bottom: var(--vui-spacing-4);
  position: sticky;

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_SM}) {
    margin: 0 calc(-1 * var(--vui-spacing-8));
    margin-top: auto;
  }

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_MD}) {
    background: none;
    border-radius: 0;
    bottom: unset;
    display: block;
    margin: 0;
    padding-bottom: 0;
    position: relative;
  }
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--vui-spacing-2);
  padding: var(--vui-spacing-2) var(--vui-spacing-4);
  width: 80%;

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_SM}) {
    padding: var(--vui-spacing-2) var(--vui-spacing-8);
  }
  @media (min-width: ${BREAKPOINTS.BREAKPOINT_MD}) {
    background: rgb(var(--vui-color-surface-secondary));
    border-radius: var(--vui-shape-corner-small);
    margin-bottom: var(--vui-spacing-5);
    padding: var(--vui-spacing-6);
    width: 100%;
  }
  @media (min-width: ${BREAKPOINTS.BREAKPOINT_LG}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const SelectionDate = styled.span`
  display: block;
  font-weight: var(--vui-type-weight-semibold);
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--vui-spacing-2);
`;

export const FooterTerms = styled.small`
  display: inline-block;
  margin-top: var(--vui-spacing-2);
  padding: 0 var(--vui-spacing-4);
  width: 80%;

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_SM}) {
    padding: 0 var(--vui-spacing-8);
  }
  @media (min-width: ${BREAKPOINTS.BREAKPOINT_MD}) {
    margin-top: 0;
    padding: 0;
  }
  @media (min-width: ${BREAKPOINTS.BREAKPOINT_LG}) {
    width: 100%;
  }
`;
