import {
  Children,
  cloneElement,
  FocusEventHandler,
  forwardRef,
  isValidElement,
  KeyboardEventHandler,
  PropsWithChildren,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import KEYBOARD_SHORTCUTS from "vui/constants/keyboardShortcuts";
import { Container } from "./styles";

export interface IProps {
  className?: string;
  id?: string;
  ignoreInteraction?: boolean;
  onClose: () => void;
  role?: "menu" | "listbox";
  tabIndex?: number;
}

const enum Direction {
  Backwards,
  Forwards,
}

export const Menu = forwardRef<HTMLUListElement, PropsWithChildren<IProps>>(
  (
    {
      children,
      className,
      id,
      ignoreInteraction,
      onClose,
      role = "menu",
      tabIndex,
    },
    ref,
  ) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const activeRef = useRef<HTMLButtonElement>(null);
    const firstUpdate = useRef(true);

    const containerRef = useRef<HTMLUListElement>(null);
    useImperativeHandle(ref, () => containerRef.current!, []);

    const items = Children.map(children, (child, index) => {
      if (!isValidElement(child)) {
        return null;
      }

      const isActive = activeIndex === index;

      return cloneElement<any>(child, {
        ref: isActive ? activeRef : undefined,
        tabIndex: isActive ? 0 : -1,
      });
    });

    const findNextFocusableChildIndex = useCallback(
      (direction: Direction = Direction.Forwards) => {
        let nextChildren = Children.toArray(children).slice(activeIndex + 1);

        if (direction === Direction.Backwards) {
          nextChildren = Children.toArray(children)
            .slice(0, activeIndex)
            .reverse();
        }

        const nextChildrenIndex = nextChildren.findIndex((child) => {
          if (!isValidElement(child)) {
            return false;
          }

          return !child.props.disabled;
        });

        if (nextChildrenIndex === -1) {
          return activeIndex;
        }

        if (direction === Direction.Backwards) {
          return activeIndex - nextChildrenIndex - 1;
        }

        return activeIndex + nextChildrenIndex + 1;
      },
      [activeIndex, children],
    );

    useEffect(() => {
      if (!firstUpdate.current && activeRef.current) {
        activeRef.current.focus();
      }
      if (firstUpdate.current) {
        firstUpdate.current = false;
      }
    }, [activeIndex]);

    const handleKeyDown: KeyboardEventHandler<HTMLUListElement> = useCallback(
      (event) => {
        switch (event.key) {
          case KEYBOARD_SHORTCUTS.ARROW_DOWN:
            event.preventDefault();
            setActiveIndex(findNextFocusableChildIndex(Direction.Forwards));
            break;
          case KEYBOARD_SHORTCUTS.ARROW_UP:
            event.preventDefault();
            setActiveIndex(findNextFocusableChildIndex(Direction.Backwards));
            break;
          case KEYBOARD_SHORTCUTS.ESCAPE:
            event.preventDefault();
            onClose();
            break;
        }
      },
      [findNextFocusableChildIndex, onClose],
    );

    const handleFocus: FocusEventHandler<HTMLUListElement> = useCallback(
      (event) => {
        if (
          activeRef.current &&
          containerRef.current &&
          event.target instanceof Node &&
          containerRef.current === event.target
        ) {
          activeRef.current.focus();
        }
      },
      [],
    );

    return (
      <Container
        className={className}
        id={id}
        onFocus={ignoreInteraction ? undefined : handleFocus}
        onKeyDown={ignoreInteraction ? undefined : handleKeyDown}
        ref={containerRef}
        role={role}
        tabIndex={tabIndex}
      >
        {ignoreInteraction ? children : items}
      </Container>
    );
  },
);

export default Menu;
