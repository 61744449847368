// TODO: Replace with VUI VideoPlayer
import OnboardingVideoPlayer from "creators/components/OnboardingVideoPlayer";
import IDS from "creators/constants/ids";
import VIDEOS from "creators/constants/videos";
import textualize from "creators/utils/textualize";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import {
  LetsGetStarted,
  Message,
  ResponsiveLayout,
  StyledHeading,
  VideoContainer,
} from "./styles";

interface IProps {
  linkToOnboarding: boolean;
}

const EmailVerificationSuccess: React.FC<React.PropsWithChildren<IProps>> = ({
  linkToOnboarding,
}) => {
  const history = useHistory();
  return (
    <ResponsiveLayout>
      <div>
        <Helmet
          title={textualize("onboarding.verifyEmail.success.title") as string}
        />

        <StyledHeading appearance="large" level="2">
          {textualize("onboarding.verifyEmail.success.heading")}
        </StyledHeading>
        <Message>
          {textualize("onboarding.verifyEmail.success.message")}
        </Message>
        <LetsGetStarted
          id={IDS.VERIFY_EMAIL.SUCCESS.START}
          onClick={() => {
            history.push(linkToOnboarding ? "/onboarding" : "/");
          }}
        >
          {textualize("onboarding.verifyEmail.success.start")}
        </LetsGetStarted>
      </div>
      <VideoContainer>
        <OnboardingVideoPlayer
          captions={VIDEOS.EMAIL_VERIFIED.CAPTIONS}
          id={IDS.VERIFY_EMAIL.SUCCESS.VIDEO}
          src={VIDEOS.EMAIL_VERIFIED.SOURCE}
        />
      </VideoContainer>
    </ResponsiveLayout>
  );
};

export default EmailVerificationSuccess;
