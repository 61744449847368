import Dropdown from "shared/components/Dropdowns/Dropdown";
import styled, { css } from "styled-components";
import BREAKPOINTS from "vui/constants/breakpoints";

export const darkTheme = {
  bg: "rgb(var(--vui-color-inverse-surface))",
  fg: "rgb(var(--vui-color-inverse-on-surface))",
  msg: "rgb(var(--vui-color-inverse-surface))",
};

export const darkEditorTheme = {
  bg: "rgb(var(--vui-color-surface-tertiary))",
  fg: "rgb(var(--vui-color-on-surface-tertiary))",
  msg: "rgb(var(--vui-color-surface-tertiary))",
};

export const lightTheme = {
  bg: "rgb(var(--vui-color-surface-primary))",
  fg: "rgb(var(--vui-color-on-surface-primary))",
  msg: "rgb(var(--vui-color-surface-primary))",
};

const headingStyles = css`
  font: var(--vui-type-title-medium-shorthand);
  letter-spacing: var(--vui-type-title-medium-spacing);
  margin-bottom: var(--vui-spacing-8);

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_LG}) {
    margin: 0 auto;
    margin-bottom: var(--vui-spacing-12);
    max-width: 33.75rem;
  }
`;

export const Header = styled.h2`
  ${headingStyles}
`;

export const HeaderDropdown = styled(Dropdown)`
  ${headingStyles}

  & .dropdown__control {
    background-color: ${({ theme }) => theme.bg};
    height: auto;
    min-height: auto;
  }

  & .dropdown__dropdown-indicator {
    background: ${({ theme }) => theme.bg};
    color: ${({ theme }) => theme.fg};

    &:hover {
      color: ${({ theme }) => theme.fg};
    }
  }

  & .dropdown__input-container {
    padding-bottom: 0;
    padding-top: 0;
  }

  & .dropdown__menu {
    background: ${({ theme }) => theme.bg};
  }

  & .dropdown__option {
    &,
    &:hover,
    &.dropdown__option--is-focused {
      color: ${({ theme }) => theme.fg};
    }

    &:hover,
    &.dropdown__option--is-focused {
      background: ${({ theme }) => `rgba(${theme.fg}, 0.25)`};
    }
  }

  & .dropdown__placeholder {
    margin-left: 0;
    margin-right: 0;
  }

  & .dropdown__value-container {
    font: var(--vui-type-title-medium-shorthand);
    letter-spacing: var(--vui-type-title-medium-spacing);
    padding: 0;
  }

  & .dropdown__single-value {
    color: ${({ theme }) => theme.fg};
    font: var(--vui-type-title-medium-shorthand);
    letter-spacing: var(--vui-type-title-medium-spacing);
    margin-left: 0;
    margin-right: 0;
  }
`;

export const HeaderInput = styled.input`
  ${headingStyles}
  background: transparent;
  border: 0;
  color: inherit;
  display: block;
  width: 100%;
`;

export const Container = styled.div`
  background: ${({ theme }) => theme.bg};
  border-radius: var(--vui-shape-corner-large);
  color: ${({ theme }) => theme.fg};
  padding: var(--vui-spacing-8) var(--vui-spacing-4);
  scroll-margin: 1rem 0;

  span.messaging {
    background-color: ${({ theme }) => theme.msg};
  }

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_LG}) {
    padding: var(--vui-spacing-24) 0;
  }

  > *:not(${Header}, ${HeaderDropdown}, ${HeaderInput}, :last-child) {
    margin-bottom: var(--vui-spacing-8);
  }
`;
