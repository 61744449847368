import { useMutation } from "@apollo/client";
import ANALYTICS from "creators/constants/analytics";
import IDS from "creators/constants/ids";
import SEND_VERIFICATION_EMAIL from "creators/mutations/sendVerificationEmail";
import { SendVerificationEmailMutation as ISendVerificationEmail } from "creators/types/graphql";
import textualize from "creators/utils/textualize";
import { useCallback, useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { EMAIL_REGEX } from "shared/constants/regex";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import styled from "styled-components";
import { FilledButton, OutlinedButton } from "vui/components/Button";
import { SnackbarContext } from "vui/components/SnackbarProvider";
import TextInput from "vui/components/TextInput";

interface IProps {
  closeForm: () => void;
  onEmailSent: () => void;
}

interface IFormValues {
  newEmail: string;
}

const SendVerificationEmail = styled(FilledButton)`
  margin: var(--vui-spacing-4);
  margin-left: 0;
`;

function IncorrectEmailForm({ closeForm, onEmailSent }: IProps) {
  const [emailSent, setEmailSent] = useState(false);
  const { addSnackbar } = useContext(SnackbarContext);

  const {
    control,
    formState: { errors, isDirty, isSubmitting, isValid },
    getValues,
    handleSubmit,
  } = useForm<IFormValues>({
    defaultValues: { newEmail: "" },
    mode: "onBlur",
  });

  const [sendVerificationEmail] = useMutation<ISendVerificationEmail>(
    SEND_VERIFICATION_EMAIL,
  );

  const onFormSubmit = useCallback(
    async (values: IFormValues) => {
      try {
        const result = await sendVerificationEmail({
          variables: {
            input: {
              pendingEmail: values.newEmail,
            },
          },
        });

        if (result.data?.viewerSendVerificationEmail) {
          setEmailSent(true);
          onEmailSent();

          fireAnalyticsEvent(
            ANALYTICS.CATEGORIES.CREATOR_VERIFICATION,
            ANALYTICS.EVENT.INCORRECT_EMAIL,
            {
              errorMessage: textualize("analytics.noError"),
              outcome: textualize("analytics.success"),
            },
          );
        }
      } catch (e) {
        addSnackbar(
          textualize(
            "onboarding.verifyEmail.unverified.incorrectEmail.error",
          ) as string,
        );

        fireAnalyticsEvent(
          ANALYTICS.CATEGORIES.CREATOR_VERIFICATION,
          ANALYTICS.EVENT.INCORRECT_EMAIL,
          {
            errorMessage: e.message,
            outcome: textualize("analytics.error"),
          },
        );
      }
    },
    [addSnackbar, onEmailSent, sendVerificationEmail],
  );

  return emailSent ? (
    <span>
      {textualize(
        "onboarding.verifyEmail.unverified.incorrectEmail.emailSent",
        { newEmail: <strong>{getValues("newEmail")}</strong> },
      )}
    </span>
  ) : (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Controller
        control={control}
        name="newEmail"
        render={({ field }) => (
          <TextInput
            {...field}
            hasError={!!errors.newEmail}
            id={IDS.VERIFY_EMAIL.UNVERIFIED.NEW_EMAIL}
            label={textualize(
              "onboarding.verifyEmail.unverified.incorrectEmail.newEmail.label",
            )}
            required={true}
            supportingText={errors.newEmail?.message}
            type="email"
          />
        )}
        rules={{
          required: textualize(
            "vuiSettings.payment.email.error.required",
          ) as string,
          pattern: {
            value: EMAIL_REGEX,
            message: textualize(
              "onboarding.verifyEmail.unverified.incorrectEmail.newEmail.error",
            ) as string,
          },
        }}
      />

      <SendVerificationEmail
        disabled={!isDirty || isSubmitting || !isValid}
        id={IDS.VERIFY_EMAIL.UNVERIFIED.SEND_EMAIL}
        type="submit"
      >
        {textualize("onboarding.verifyEmail.unverified.incorrectEmail.submit")}
      </SendVerificationEmail>
      <OutlinedButton
        id={IDS.VERIFY_EMAIL.UNVERIFIED.CANCEL}
        onClick={closeForm}
        type="button"
      >
        {textualize("common.cancel")}
      </OutlinedButton>
    </form>
  );
}

export default IncorrectEmailForm;
