// TODO: Replace with VUI VideoPlayer
import LocationInput from "creators/components/LocationInput";
import OnboardingVideoPlayer from "creators/components/OnboardingVideoPlayer";
import ANALYTICS from "creators/constants/analytics";
import IDS from "creators/constants/ids";
import { ONBOARDING_INDICES, TOTAL_STEPS } from "creators/constants/onboarding";
import VIDEOS from "creators/constants/videos";
import { getStepForCreator } from "creators/hooks/useOnboardingProgressAnalytics";
import { IFormValues } from "creators/types/onboarding";
import textualize from "creators/utils/textualize";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import ProgressBar from "vui/components/ProgressBar";
import {
  Back,
  Next,
  ResponsiveLayout,
  StyledHeading,
  VideoContainer,
} from "../styles";

export interface IProps {
  creatorExternalID: string;
  initialValues: IFormValues;
  onBack: () => void;
  onNext: () => void;
  onSubmit: (values: IFormValues) => Promise<void>;
}

const LocationForm: React.FC<React.PropsWithChildren<IProps>> = ({
  creatorExternalID,
  initialValues,
  onBack,
  onNext,
  onSubmit,
}) => {
  const [videoInteraction, setVideoInteraction] = useState(false);

  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = useForm<IFormValues>({
    defaultValues: initialValues,
    mode: "onChange",
  });

  const onFormSubmit = useCallback(
    async (values: IFormValues) => {
      if (initialValues.location === values.location) {
        onNext();
      } else {
        try {
          await onSubmit(values);

          if (getStepForCreator(creatorExternalID) === 2) {
            fireAnalyticsEvent(
              ANALYTICS.CATEGORIES.ONBOARDING,
              ANALYTICS.EVENT.ONBOARDING_LOCATION,
              {
                videoInteraction,
              },
            );
          }
        } catch {
          // Handled in OnboardingSteps
        }
      }
    },
    [
      creatorExternalID,
      initialValues.location,
      onNext,
      onSubmit,
      videoInteraction,
    ],
  );

  return (
    <ResponsiveLayout>
      <div>
        <ProgressBar
          completed={false}
          count={ONBOARDING_INDICES.LOCATION}
          id={IDS.ONBOARDING.PROGRESS}
          label={textualize("onboarding.progress") as string}
          totalRequired={TOTAL_STEPS}
        />

        <StyledHeading appearance="medium" level="2">
          {textualize("onboarding.location.heading")}
        </StyledHeading>

        <form
          id={IDS.ONBOARDING.LOCATION.ID}
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <Controller
            control={control}
            name="location"
            render={({ field }) => (
              <LocationInput
                {...field}
                hasError={!!errors.location}
                id={IDS.ONBOARDING.LOCATION.INPUT}
                label={textualize("onboarding.location.label")}
                supportingText={
                  errors.location?.message ||
                  textualize("onboarding.location.help")
                }
              />
            )}
            rules={{
              required: textualize("onboarding.location.error") as string,
            }}
          />

          <Back id={IDS.ONBOARDING.BACK} onClick={onBack}>
            {textualize("onboarding.back")}
          </Back>

          <Next
            disabled={isSubmitting || !isValid}
            id={IDS.ONBOARDING.NEXT}
            type="submit"
          >
            {textualize("onboarding.next")}
          </Next>
        </form>
      </div>

      <VideoContainer>
        <OnboardingVideoPlayer
          captions={VIDEOS.LOCATION.CAPTIONS}
          id={IDS.ONBOARDING.LOCATION.VIDEO}
          onVideoInteraction={() => setVideoInteraction(true)}
          src={VIDEOS.LOCATION.SOURCE}
        />
      </VideoContainer>
    </ResponsiveLayout>
  );
};

export default LocationForm;
