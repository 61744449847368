import { forwardRef, PropsWithChildren } from "react";
import styled from "styled-components";

const StyledTab = styled.li`
  // Keep at the top to prevent removing new styles
  all: unset;

  align-items: center;
  box-sizing: border-box;
  color: rgba(var(--vui-color-on-surface-primary), 0.6);
  cursor: pointer;
  display: flex;
  height: var(--vui-spacing-12);
  flex: 1 1 100%;
  flex-direction: row;
  font: var(--vui-type-label-large-shorthand);
  font-weight: var(--vui-type-weight-semibold);
  justify-content: center;
  letter-spacing: var(--vui-type-label-large-spacing);
  padding: 0 var(--vui-spacing-4);

  &:focus-visible,
  &:hover {
    color: rgb(var(--vui-color-on-surface-primary));
  }

  &:focus-visible {
    border-radius: var(--vui-shape-corner-small);
    outline: rgb(var(--vui-color-active-surface-outline)) solid
      var(--vui-state-focus-indicator-thickness);
    outline-offset: calc(-1 * var(--vui-state-focus-indicator-thickness));
  }

  &[aria-selected="true"] {
    color: rgb(var(--vui-color-on-surface-primary));
  }
`;

export interface IProps {
  active?: boolean;
  as?: React.ElementType;
  className?: string;
  controls?: string;
  id?: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  tabIndex?: number;
}

const Tab = forwardRef<HTMLElement, PropsWithChildren<IProps>>(
  (
    { active, as, children, className, controls, id, onClick, tabIndex },
    ref,
  ) => {
    return (
      <StyledTab
        aria-controls={controls}
        aria-selected={active ? true : false}
        as={as}
        className={className}
        id={id}
        onClick={onClick}
        ref={ref}
        role="tab"
        tabIndex={tabIndex}
      >
        {children}
      </StyledTab>
    );
  },
);

export default Tab;
