// TODO: Replace with VUI OverlayBackdrop
import OverlayBackdrop from "creators/components/OverlayBackdrop";
import IDS from "creators/constants/ids";
import BriefTeaser from "creators/pages/BriefTeaser";
import { BriefAnalyticsContext } from "creators/providers/BriefAnalyticsProvider";
import { useCallback, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import styled from "styled-components";
import { TopBarActionButton, TopBarIconBlock } from "vui/components/TopBar";
import VisuallyHidden from "vui/components/VisuallyHidden";
import BREAKPOINTS from "vui/constants/breakpoints";
import IconArrowBack from "vui/icons/arrow-back.svg";

const Container = styled.section`
  background-color: rgb(var(--vui-color-surface-primary));
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100dvh;
  left: 0;
  margin: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  will-change: transform;
  z-index: 10;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const TopBar = styled.div`
  align-items: center;
  background-color: rgb(var(--vui-color-surface-primary));
  display: flex;
  height: var(--vui-spacing-16);
  padding: var(--vui-spacing-2) var(--vui-spacing-1);
  position: sticky;
  top: 0;
  z-index: 2;

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_SM}) {
    padding: var(--vui-spacing-2) var(--vui-spacing-4);
  }
`;

const AnimatedContainer = animated(Container);
const AnimatedOverlayBackdrop = animated(OverlayBackdrop);

// Overlay needs to open in the background to prevent the user scrolling the page
// while a teaser is open.

interface IRouteParams {
  id?: string;
  previewHash?: string;
  slug?: string;
}

const BriefTeaserMobile: React.FC = () => {
  const { cleanAnalyticsData } = useContext(BriefAnalyticsContext);

  const history = useHistory();

  const match = useRouteMatch<IRouteParams>({
    exact: true,
    path: ["/brief/:id(\\d+)/:slug?", "/brief/:id(\\d+)/teaser/:previewHash"],
  });

  const closeBrief = useCallback(() => {
    cleanAnalyticsData();
    history.push("/");
  }, [cleanAnalyticsData, history]);

  const transitionData = {
    id: match?.params.id,
    previewHash: match?.params.previewHash,
    slug: match?.params.slug,
  };

  const navMenuTransitions = useTransition(transitionData, {
    enter: { transform: "translateY(0%)" },
    from: {
      transform: "translateY(100%)",
    },
    key: (item: IRouteParams) => item.id,
    leave: { transform: "translateY(100%)" },
  });

  const navOverlayTransitions = useTransition(transitionData, {
    config: {
      // Hide overlay faster than brief
      clamp: true,
      friction: 13,
    },
    enter: { opacity: 1 },
    from: { opacity: 0 },
    key: (item: IRouteParams) => item.id,
    leave: { opacity: 0 },
  });

  return (
    <>
      {/* Overlay needs to open in the background to prevent the user scrolling the page while a teaser is open. */}
      {navOverlayTransitions(
        (props, { id }, { key }) =>
          id && <AnimatedOverlayBackdrop key={key} style={props} />,
      )}

      {navMenuTransitions(
        (props, { id, previewHash, slug }, { key }) =>
          id && (
            <AnimatedContainer key={key} style={props}>
              <Wrapper>
                <TopBar>
                  <TopBarActionButton
                    id={IDS.BRIEF_TEASER.CLOSE}
                    onClick={closeBrief}
                  >
                    <TopBarIconBlock component={IconArrowBack} />
                    <VisuallyHidden>Back</VisuallyHidden>
                  </TopBarActionButton>
                </TopBar>

                <BriefTeaser
                  briefID={id}
                  previewHash={previewHash}
                  slug={slug}
                />
              </Wrapper>
            </AnimatedContainer>
          ),
      )}
    </>
  );
};

export default BriefTeaserMobile;
