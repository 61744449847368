import { TermsAndConditionsLink } from "creators/components/TermsAndConditionsLinks";
import DATE_FORMATS from "creators/constants/dateFormats";
import IDS from "creators/constants/ids";
import textualize from "creators/utils/textualize";
import { format } from "date-fns-tz";
import { FilledButton, OutlinedButton } from "vui/components/Button";
import {
  Banner,
  ButtonGroup,
  Container,
  FooterTerms,
  SelectionDate,
} from "./styles";

export interface IProps {
  applicationsClosed?: boolean;
  applied?: boolean;
  applyLoading?: boolean;
  className?: string;
  creatorSelectionDate?: Date;
  handleApplicationClick?: () => void;
  handleWithdrawClick?: () => void;
  withdrawLoading?: boolean;
}

const BriefTeaserFooter: React.FC<IProps> = ({
  applicationsClosed,
  applied,
  applyLoading,
  className,
  creatorSelectionDate,
  handleApplicationClick,
  handleWithdrawClick,
  withdrawLoading,
}) => {
  return (
    <Container className={className}>
      <Banner>
        {creatorSelectionDate && !applicationsClosed && (
          <>
            <div>
              <span>
                {textualize("vuiLiveBriefs.teaser.footer.deadline", {
                  date: (
                    <SelectionDate>
                      {format(creatorSelectionDate!, DATE_FORMATS.DATE_TIME)}
                    </SelectionDate>
                  ),
                })}
              </span>
            </div>
            <ButtonGroup>
              {applied && (
                <OutlinedButton
                  disabled={withdrawLoading}
                  onClick={handleWithdrawClick}
                >
                  {textualize("vuiLiveBriefs.teaser.button.withdraw")}
                </OutlinedButton>
              )}
              <FilledButton
                disabled={applied || applyLoading}
                onClick={handleApplicationClick}
              >
                {applied
                  ? textualize("vuiLiveBriefs.teaser.button.applied")
                  : textualize("vuiLiveBriefs.teaser.button.apply")}
              </FilledButton>
            </ButtonGroup>
          </>
        )}

        {applicationsClosed && (
          <div>{textualize("vuiLiveBriefs.teaser.applicationsClosed")}</div>
        )}
      </Banner>

      <FooterTerms>
        {textualize("vuiLiveBriefs.teaser.footer.terms", {
          termsAndConditions: (
            <TermsAndConditionsLink id={IDS.TERMS_AND_CONDITIONS.LINK} />
          ),
        })}
      </FooterTerms>
    </Container>
  );
};

export default BriefTeaserFooter;
