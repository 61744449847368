import textualize from "creators/utils/textualize";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ProgressCircle from "vui/components/ProgressCircle";

export const StyledLoading = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => !visible && "none"};
  height: var(--vui-spacing-8);
  left: calc(50% - var(--vui-spacing-4));
  position: absolute;
  top: calc(50% - var(--vui-spacing-4));
  width: var(--vui-spacing-8);
`;

// TODO: Move to vui as generic component if required elsewhere
export const LoadingDelayed = () => {
  const timer = useRef<number>();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    timer.current = window.setTimeout(() => setVisible(true), 500);

    return () => window.clearTimeout(timer.current);
  }, []);

  return (
    <StyledLoading visible={visible}>
      <ProgressCircle
        aria-label={textualize("common.loading") as string}
        indeterminate={true}
      />
    </StyledLoading>
  );
};

export default LoadingDelayed;
