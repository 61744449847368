import {
  BlockContainer,
  IProps as IBlockContainer,
} from "creators/components/Brief/BlockContainer/BlockContainer";
import DIMENSIONS from "creators/constants/dimensions";
import styled, { css } from "styled-components";
import BREAKPOINTS from "vui/constants/breakpoints";

export const textStyles = css`
  p,
  ol,
  ul {
    margin-bottom: var(--v-spacing-6);

    &:last-child {
      margin-bottom: 0;
    }
  }

  ol {
    list-style: decimal;
    margin-left: var(--v-spacing-4);
  }

  ul {
    list-style: disc;
    margin-left: var(--v-spacing-4);

    ul {
      list-style: circle;
    }
  }

  li {
    p {
      margin-bottom: var(--v-spacing-2);
    }
  }
`;

export const Container = styled(BlockContainer)<IBlockContainer>`
  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    max-width: unset;
  }

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_LG}) {
    max-width: 33.75rem;
  }

  div.ProseMirror {
    background: ${({ theme }) => theme.bg};
    border: none;
    color: ${({ theme }) => theme.fg};
    display: block;
    resize: none;
    width: 100%;
    outline: none;
  }

  ${textStyles}
`;
