import PageContainer from "creators/components/PageContainer";
import DIMENSIONS from "creators/constants/dimensions";
import textualize from "creators/utils/textualize";
import styled from "styled-components";
import Heading from "vui/components/Heading";

interface IProps {
  firstName: string;
  id: string;
}

const StyledHeading = styled(Heading)`
  margin-bottom: var(--vui-spacing-12);
`;

const Window = styled.div<{ showVideo?: boolean }>`
  background: rgb(var(--vui-color-surface-primary));
  border-radius: var(--vui-shape-corner-small);
  padding: var(--vui-spacing-4);

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    padding: var(--vui-spacing-8);
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    padding: var(--vui-spacing-16);
  }
`;

const WindowLayout: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  firstName,
  id,
}) => (
  <PageContainer id={id}>
    <StyledHeading appearance="large" level="1">
      {textualize("onboarding.welcome", { firstName })}
    </StyledHeading>
    <Window>{children}</Window>
  </PageContainer>
);

export default WindowLayout;
