import styled from "styled-components";
import { TextButton } from "vui/components/Button";
import Icon from "vui/components/Icon";
import ArrowForwardIcon from "vui/icons/arrow-forward.svg";

export const ArrowButton = styled(TextButton)`
  align-self: stretch;
  background-color: rgb(var(--vui-color-surface-secondary));
  height: unset;
  padding: var(--vui-spacing-2);
`;

export const Container = styled.nav`
  border-radius: var(--vui-shape-corner-full);
  display: flex;
  gap: var(--vui-spacing-2);
  justify-content: space-between;
  height: var(--vui-spacing-20);
`;

export const Scroller = styled.div`
  align-items: center;
  background-color: rgb(var(--vui-color-surface-secondary));
  border-radius: var(--vui-shape-corner-full);
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: space-between;
  overflow: auto;
  scroll-behavior: smooth;
`;

export const NextArrow = styled(Icon).attrs({ component: ArrowForwardIcon })`
  height: var(--vui-spacing-6);
  width: var(--vui-spacing-6);
`;

export const PreviousArrow = styled(NextArrow)`
  transform: rotate(180deg);
`;
