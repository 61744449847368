import { ChangeEvent, forwardRef, useState } from "react";
import { SwitchInput } from "./styles";

export interface IProps {
  "aria-describedby"?: string;
  "aria-label"?: string;
  checked?: boolean;
  className?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  hasError?: boolean;
  id?: string;
  name?: string;
  onChange?: (e: ChangeEvent) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onMouseOut?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  value?: string;
}

// TODO: Investigate MUI icon option functionality
export const Switch = forwardRef<HTMLInputElement, IProps>(
  (
    { hasError = false, onMouseDown, onMouseOut, onMouseUp, ...props }: IProps,
    ref,
  ) => {
    const [pressed, setPressed] = useState(false);

    return (
      <SwitchInput
        hasError={hasError}
        onMouseDown={(e) => {
          if (onMouseDown) {
            onMouseDown(e);
          }
          setPressed(true);
        }}
        onMouseOut={(e) => {
          if (onMouseOut) {
            onMouseOut(e);
          }
          setPressed(false);
        }}
        onMouseUp={(e) => {
          if (onMouseUp) {
            onMouseUp(e);
          }
          setPressed(false);
        }}
        pressed={pressed}
        ref={ref}
        type="checkbox"
        {...props}
      />
    );
  },
);

export default Switch;
