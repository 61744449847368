import {
  BackgroundCircle,
  Circle,
  CircleOutline,
  CIRCUMFERENCE,
  Container,
  DIAMETER,
  RADIUS,
} from "./styles";

export interface IProps {
  "aria-label"?: string;
  className?: string;
  id?: string;
  indeterminate?: boolean;
  value?: number;
}

export const ProgressCircle: React.FC<React.PropsWithChildren<IProps>> = ({
  "aria-label": ariaLabel,
  className,
  id,
  indeterminate = false,
  value = 0,
}) => {
  const percentage = Math.max(0, Math.min((value * 100) / 1 / 100, 1));

  return (
    <Container
      aria-label={ariaLabel}
      aria-valuemax={1}
      aria-valuemin={0}
      aria-valuenow={!indeterminate ? value : undefined}
      className={className}
      id={id}
      role="progressbar"
    >
      <svg
        viewBox={`0 0 ${DIAMETER} ${DIAMETER}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        {indeterminate ? (
          <g>
            <Circle
              cx={DIAMETER / 2}
              cy={DIAMETER / 2}
              r={RADIUS}
              strokeDasharray={CIRCUMFERENCE}
              strokeDashoffset={CIRCUMFERENCE * 0.5}
              strokeLinecap="round"
              transform={`rotate(-90, ${DIAMETER / 2}, ${DIAMETER / 2})`}
            />
            <animateTransform
              attributeName="transform"
              dur="1s"
              from={`0 ${DIAMETER / 2} ${DIAMETER / 2}`}
              repeatCount="indefinite"
              to={`360 ${DIAMETER / 2} ${DIAMETER / 2}`}
              type="rotate"
            />
          </g>
        ) : (
          <g>
            <BackgroundCircle cx={DIAMETER / 2} cy={DIAMETER / 2} r={RADIUS} />

            <CircleOutline
              cx={DIAMETER / 2}
              cy={DIAMETER / 2}
              r={RADIUS}
              strokeDasharray={CIRCUMFERENCE}
              strokeDashoffset={CIRCUMFERENCE * (1 - percentage)}
              strokeLinecap="round"
              transform={`rotate(-90, ${DIAMETER / 2}, ${DIAMETER / 2})`}
            />

            <Circle
              cx={DIAMETER / 2}
              cy={DIAMETER / 2}
              r={RADIUS}
              strokeDasharray={CIRCUMFERENCE}
              strokeDashoffset={CIRCUMFERENCE * (1 - percentage)}
              strokeLinecap="round"
              transform={`rotate(-90, ${DIAMETER / 2}, ${DIAMETER / 2})`}
            />
          </g>
        )}
      </svg>
    </Container>
  );
};

export default ProgressCircle;
