import DIMENSIONS from "creators/constants/dimensions";
import styled from "styled-components";
import { FilledButton } from "vui/components/Button";
import Heading from "vui/components/Heading";

export const StyledHeading = styled(Heading)`
  margin-bottom: var(--vui-spacing-12);
`;

export const LetsGetStarted = styled(FilledButton)`
  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    display: inline;
  }
`;

export const Message = styled.p`
  margin-bottom: var(--vui-spacing-12);
`;

export const VideoContainer = styled.div`
  align-self: center;
  flex: 1;
  margin: 0 0 var(--vui-spacing-12) 0;
  max-height: 19.4375rem;
  max-width: 19.4375rem;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    max-height: 31.75rem;
    max-width: 31.75rem;
  }

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    align-self: auto;
    margin: auto 0 auto var(--vui-spacing-16);
  }
`;

export const ResponsiveLayout = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    flex-direction: row;
  }

  > div {
    flex: 1;
  }
`;
