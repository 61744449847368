import Countdown from "creators/components/Countdown";
import DATE_FORMATS from "creators/constants/dateFormats";
import { BriefState, BriefType, SubmissionState } from "creators/types/graphql";
import textualize from "creators/utils/textualize";
import { format, isBefore } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import formatNumberToCurrency from "shared/utils/formatNumberToCurrency";
import Heading from "vui/components/Heading";
import Tag from "vui/components/Tag";
import {
  ApplyTime,
  Details,
  Image,
  Layout,
  Payment,
  TagWrapper,
} from "./styles";

export interface IProps {
  brandImage: string;
  brandName: string;
  briefName: string;
  briefState: BriefState;
  briefType: BriefType;
  className?: string;
  creatorSelectionDate?: Date;
  paymentAmount?: number;
  paymentCurrencyCode?: string;
  submissionState?: SubmissionState;
  submittedAt?: Date;
}

const BriefHeader: React.FC<IProps> = ({
  brandImage,
  brandName,
  briefName,
  briefState,
  briefType,
  className,
  creatorSelectionDate,
  paymentAmount,
  paymentCurrencyCode,
  submissionState,
  submittedAt,
}) => {
  // TODO: Look at removing this state in favour of swapping textualize based on remaining days
  const [applicationsClosed, setApplicationsClosed] = useState(
    briefState === BriefState.Closed ||
      (creatorSelectionDate && isBefore(creatorSelectionDate, new Date())),
  );

  const closeApplications = useCallback(() => {
    setApplicationsClosed(true);
  }, []);

  const completed = useMemo(
    () => submittedAt && submissionState === SubmissionState.Completed,
    [submittedAt, submissionState],
  );
  const didNotSubmit = useMemo(
    () => !submittedAt && submissionState === SubmissionState.Completed,
    [submittedAt, submissionState],
  );

  // TODO: Clarify exactly how much of this is needed - component can be reused for all briefs and this may not all be needed
  let status;
  if (completed) {
    status = textualize("vuiLiveBriefs.briefs.status.submittedDate", {
      date: format(submittedAt!, DATE_FORMATS.SHORT_DATE),
    });
  } else if (didNotSubmit) {
    status = textualize("vuiLiveBriefs.briefs.status.didNotSubmit");
  } else if (submissionState === SubmissionState.Applied) {
    status = textualize("vuiLiveBriefs.briefs.status.applied");
  } else if (submissionState === SubmissionState.InviteDeclined) {
    status = textualize("vuiLiveBriefs.briefs.status.inviteDeclined");
  } else if (submissionState === SubmissionState.Declined) {
    status = textualize("vuiLiveBriefs.briefs.status.declined");
  } else if (applicationsClosed) {
    status = textualize("vuiLiveBriefs.briefs.status.applicationsClosed");
  } else {
    status = textualize("vuiLiveBriefs.briefs.status.timeToApply", {
      time: creatorSelectionDate ? (
        <Countdown
          date={creatorSelectionDate}
          largestOnly={true}
          onComplete={closeApplications}
        />
      ) : (
        ""
      ),
    });
  }

  return (
    <Layout className={className}>
      <div>
        <Image alt={brandName} src={brandImage} />
      </div>

      <Details>
        <Heading appearance="medium" level="1">
          {briefName}
        </Heading>
        <ApplyTime>
          {creatorSelectionDate && <>{status} - </>}
          {brandName}
        </ApplyTime>
        {paymentAmount && paymentCurrencyCode && (
          <Payment>
            {formatNumberToCurrency(paymentAmount, "GB", paymentCurrencyCode)}
          </Payment>
        )}
        <TagWrapper>
          <Tag>
            {briefType === BriefType.MotionGraphics
              ? textualize("vuiCommon.briefType.remix")
              : textualize("vuiCommon.briefType.cgc")}
          </Tag>
        </TagWrapper>
      </Details>
    </Layout>
  );
};

export default BriefHeader;
