// TODO: Replace with VUI Modal/Dialog
import StandardModal from "creators/components/Modal/StandardModal";
import styled from "styled-components";

export const StyledStandardModal = styled(StandardModal)`
  background: rgb(var(--vui-color-surface-primary));
  color: rgb(var(--vui-color-on-surface-primary));
  display: flex;
  flex-direction: column;
  gap: var(--vui-spacing-4);
  padding: var(--vui-spacing-6);
  width: 100%;
  z-index: 100;
`;

export const Cta = styled.div`
  display: flex;
  justify-content: flex-end;
`;
