import { INavLink } from "creators/types/navLinks";
import { FC, useMemo } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import HorizontalSubNav from "vui/components/HorizontalSubNav";
import HorizontalSubNavItem from "vui/components/HorizontalSubNavItem";
import HorizontalSubNavLink from "vui/components/HorizontalSubNavLink";

const StyledHorizontalSubNav = styled(HorizontalSubNav)<{ $shadow?: boolean }>`
  & > * {
    box-shadow: 0;
    transition: box-shadow 0.3s ease;
  }

  ${({ $shadow }) =>
    $shadow &&
    css`
      & > * {
        box-shadow: var(--vui-shadow-small);
      }
    `}
`;

export interface IProps {
  id: string;
  label: string;
  links: INavLink[];
  showShadow?: boolean;
}

const HorizontalNav: FC<IProps> = ({ id, label, links, showShadow }) => {
  const location = useLocation();
  const history = useHistory();

  const activeIndex = useMemo(
    () => links.findIndex((link) => location.pathname === link.to),
    [links, location.pathname],
  );

  const onNextClick = () => {
    const nextLink = links[activeIndex + 1];

    if (nextLink) {
      history.push(nextLink.to);
    }
  };

  const onPreviousClick = () => {
    const previousLink = links[activeIndex - 1];

    if (previousLink) {
      history.push(previousLink.to);
    }
  };

  return (
    <StyledHorizontalSubNav
      $shadow={showShadow}
      ariaLabel={label}
      id={id}
      onNextClick={onNextClick}
      onPreviousClick={onPreviousClick}
    >
      {links.map((item, index) => (
        <HorizontalSubNavLink
          $active={activeIndex === index}
          as={Link}
          key={item.to}
          to={item.to}
        >
          <HorizontalSubNavItem>{item.name}</HorizontalSubNavItem>
        </HorizontalSubNavLink>
      ))}
    </StyledHorizontalSubNav>
  );
};

export default HorizontalNav;
