import styled from "styled-components";
import Heading from "../Heading";

export const BaseTooltipContainer = styled.div`
  margin: auto;
  white-space: pre-wrap;
  width: max-content;
  z-index: 300; // Above canvas panels
`;

export const TooltipContainer = styled(BaseTooltipContainer)`
  background: rgb(var(--vui-color-tooltip-surface));
  border-radius: var(--vui-shape-corner-extra-small);
  color: rgb(var(--vui-color-on-tooltip-surface));
  max-width: var(--vui-spacing-52);
  min-height: var(--vui-spacing-6);
  padding: var(--vui-spacing-2);
`;

export const RichTooltipContainer = styled(BaseTooltipContainer)`
  background: rgb(var(--vui-color-surface-primary));
  border: 1px solid rgb(var(--vui-color-surface-primary-outline));
  border-radius: var(--vui-shape-corner-medium);
  color: rgb(var(--vui-color-on-surface-primary));
  max-width: calc(var(--vui-spacing-64) + (2 * var(--vui-spacing-4)));
  padding: var(--vui-spacing-3) var(--vui-spacing-4) var(--vui-spacing-2);
`;

export const Title = styled(Heading)`
  color: rgb(var(--vui-color-on-surface-secondary));
  margin-bottom: var(--vui-spacing-2);
`;

export const Actions = styled.div`
  display: flex;
  gap: var(--vui-spacing-2);
  margin: var(--vui-spacing-3) calc(-1 * var(--vui-spacing-2)) 0;
`;
