import styled from "styled-components";
import Heading from "vui/components/Heading";

export const StyledHeading = styled(Heading)`
  margin-bottom: var(--vui-spacing-2);
`;

export const Subheading = styled(Heading)`
  margin-bottom: var(--vui-spacing-2);
`;

export const Message = styled.p`
  margin-bottom: var(--vui-spacing-12);
`;
