import { useQuery } from "@apollo/client";
import BriefList from "creators/components/BriefList";
import DataError from "creators/components/DataError";
import LiveBriefInvite from "creators/components/LiveBriefInvite";
import LoadingDelayed from "creators/components/LoadingDelayed";
import IDS from "creators/constants/ids";
import BriefTeaserContainer from "creators/pages/BriefTeaserContainer";
import BriefTeaserMobile from "creators/pages/BriefTeaserMobile";
import BriefAnalyticsProvider from "creators/providers/BriefAnalyticsProvider";
import {
  ScreenSize,
  ViewportContext,
} from "creators/providers/ViewportProvider";
import { GET_VIEWER } from "creators/queries/GetViewer";
import {
  BriefType,
  CreatorSkill,
  GetViewerQuery as IGetViewer,
} from "creators/types/graphql";
import textualize from "creators/utils/textualize";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Tab from "vui/components/Tab";
import VisuallyHidden from "vui/components/VisuallyHidden";
import {
  BriefsColumn,
  LandingPrompt,
  LandingTitle,
  LandingView,
  LandingWrapper,
  PageLayout,
  Placeholder,
  StyledPageContainer,
  StyledTabBar,
} from "./styles";

const MAX_PER_PAGE = 16;

const skillsToValues = {
  [CreatorSkill.LiveAction]: {
    name: textualize("vuiCommon.briefType.cgc") as string,
  },
  [CreatorSkill.MotionGraphics]: {
    name: textualize("vuiCommon.briefType.remix") as string,
  },
};

const LiveBriefs: React.FC = () => {
  const size = useContext(ViewportContext);

  const [briefList, setBriefList] = useState(BriefType.LiveAction);
  const { data, loading, error } = useQuery<IGetViewer>(GET_VIEWER);

  useEffect(() => {
    if (!data?.viewer || !data?.viewer?.skills) {
      return;
    }

    if (data?.viewer.skills.length > 1) {
      return;
    }

    if (data?.viewer.skills.includes(CreatorSkill.LiveAction)) {
      setBriefList(BriefType.LiveAction);
    } else if (data?.viewer.skills.includes(CreatorSkill.MotionGraphics)) {
      setBriefList(BriefType.MotionGraphics);
    }
  }, [data?.viewer]);

  if (loading) {
    return <LoadingDelayed />;
  }

  if (error) {
    return <DataError error={error} />;
  }

  if (!data?.viewer) {
    return null;
  }

  const { firstName, skills } = data.viewer;

  return (
    <StyledPageContainer id={IDS.LIVE_BRIEFS.PAGE}>
      <PageLayout>
        <Helmet title={textualize("vuiLiveBriefs.title") as string} />

        <LiveBriefInvite />

        <BriefAnalyticsProvider>
          {size === ScreenSize.Mobile ? (
            <BriefTeaserMobile />
          ) : (
            <BriefTeaserContainer />
          )}
        </BriefAnalyticsProvider>

        <LandingWrapper>
          <LandingView>
            <LandingTitle appearance="large" level="1">
              {textualize("vuiLiveBriefs.landing.title", { firstName })}
            </LandingTitle>
            <LandingPrompt>
              {textualize("vuiLiveBriefs.landing.prompt")}
            </LandingPrompt>
          </LandingView>
        </LandingWrapper>

        <BriefsColumn>
          {skills && (
            <>
              {skills.length > 1 ? (
                <StyledTabBar>
                  <Tab
                    active={briefList === BriefType.LiveAction}
                    as="button"
                    controls={IDS.LIVE_BRIEFS.TAB_PANEL.CGC}
                    id={IDS.LIVE_BRIEFS.TAB.CGC}
                    onClick={() => setBriefList(BriefType.LiveAction)}
                  >
                    {textualize("vuiCommon.briefType.cgc")}
                  </Tab>
                  <Tab
                    active={briefList === BriefType.MotionGraphics}
                    as="button"
                    controls={IDS.LIVE_BRIEFS.TAB_PANEL.REMIX}
                    id={IDS.LIVE_BRIEFS.TAB.REMIX}
                    onClick={() => setBriefList(BriefType.MotionGraphics)}
                  >
                    {textualize("vuiCommon.briefType.remix")}
                  </Tab>
                </StyledTabBar>
              ) : (
                <Placeholder>
                  <VisuallyHidden as="h2">
                    {skillsToValues[skills[0]].name}
                  </VisuallyHidden>
                </Placeholder>
              )}
            </>
          )}

          {skills?.includes(CreatorSkill.LiveAction) &&
            briefList === BriefType.LiveAction && (
              <BriefList
                alternative={
                  skills.length > 1 ? BriefType.MotionGraphics : null
                }
                aria-labelledby={IDS.LIVE_BRIEFS.TAB.CGC}
                id={IDS.LIVE_BRIEFS.TAB_PANEL.CGC}
                maxPerPage={MAX_PER_PAGE}
                title={
                  textualize("vuiLiveBriefs.briefList.title.cgc") as string
                }
                type={BriefType.LiveAction}
              />
            )}
          {skills?.includes(CreatorSkill.MotionGraphics) &&
            briefList === BriefType.MotionGraphics && (
              <BriefList
                alternative={skills.length > 1 ? BriefType.LiveAction : null}
                aria-labelledby={IDS.LIVE_BRIEFS.TAB.REMIX}
                id={IDS.LIVE_BRIEFS.TAB_PANEL.REMIX}
                maxPerPage={MAX_PER_PAGE}
                title={
                  textualize("vuiLiveBriefs.briefList.title.remix") as string
                }
                type={BriefType.MotionGraphics}
              />
            )}
        </BriefsColumn>
      </PageLayout>
    </StyledPageContainer>
  );
};

export default LiveBriefs;
