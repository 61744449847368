import { useMutation } from "@apollo/client";
import ANALYTICS from "creators/constants/analytics";
import ERRORS from "creators/constants/errors";
import IDS from "creators/constants/ids";
import SEND_VERIFICATION_EMAIL from "creators/mutations/sendVerificationEmail";
import { SendVerificationEmailMutation as ISendVerificationEmail } from "creators/types/graphql";
import textualize from "creators/utils/textualize";
import { useCallback, useContext, useState } from "react";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import getGraphQLErrors from "shared/utils/getGraphQLErrors";
import styled from "styled-components";
import { FilledButton } from "vui/components/Button";
import { SnackbarContext } from "vui/components/SnackbarProvider";

interface IProps {
  email: string;
}

const Container = styled.div`
  margin-bottom: var(--vui-spacing-12);
`;

export const ResendEmail = ({ email }: IProps) => {
  const [emailSent, setEmailSent] = useState(false);
  const [sending, setSending] = useState(false);

  const { addSnackbar } = useContext(SnackbarContext);

  const [sendVerificationEmail] = useMutation<ISendVerificationEmail>(
    SEND_VERIFICATION_EMAIL,
  );

  const resend = useCallback(async () => {
    setSending(true);

    try {
      await sendVerificationEmail({
        variables: {
          input: {
            pendingEmail: email,
          },
        },
      });

      setEmailSent(true);

      fireAnalyticsEvent(
        ANALYTICS.CATEGORIES.CREATOR_VERIFICATION,
        ANALYTICS.EVENT.RESEND_VERIFICATION_EMAIL,
        {
          errorMessage: textualize("analytics.noError"),
          outcome: textualize("analytics.success"),
        },
      );
    } catch (e) {
      setSending(false);

      const errorMessage = getGraphQLErrors(e).includes(
        ERRORS.CREATOR_EMAIL_VERIFIED,
      )
        ? textualize(
            "onboarding.verifyEmail.unverified.resendEmail.error.verified",
          )
        : textualize(
            "onboarding.verifyEmail.unverified.resendEmail.error.default",
          );
      addSnackbar(errorMessage as string);

      fireAnalyticsEvent(
        ANALYTICS.CATEGORIES.CREATOR_VERIFICATION,
        ANALYTICS.EVENT.RESEND_VERIFICATION_EMAIL,
        {
          errorMessage: e.message,
          outcome: textualize("analytics.error"),
        },
      );
    }
  }, [addSnackbar, email, sendVerificationEmail]);

  return (
    <Container>
      {emailSent ? (
        <div>
          {textualize(
            "onboarding.verifyEmail.unverified.resendEmail.emailSent",
          )}
        </div>
      ) : (
        <FilledButton
          disabled={sending}
          id={IDS.VERIFY_EMAIL.UNVERIFIED.RESEND_EMAIL}
          onClick={resend}
        >
          {textualize("onboarding.verifyEmail.unverified.resendEmail.label")}
        </FilledButton>
      )}
    </Container>
  );
};

export default ResendEmail;
