import DIMENSIONS from "creators/constants/dimensions";
import styled from "styled-components";
import { FilledButton, OutlinedButton } from "vui/components/Button";
import Heading from "vui/components/Heading";

export const Back = styled(OutlinedButton)`
  margin-right: var(--vui-spacing-4);
`;

export const StyledHeading = styled(Heading)`
  margin-bottom: var(--vui-spacing-8);
  margin-top: var(--vui-spacing-4);

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    margin-bottom: var(--vui-spacing-12);
  }
`;

export const Next = styled(FilledButton)`
  &:disabled {
    cursor: auto;
  }
  margin-top: var(--vui-spacing-4);
`;

export const ResponsiveLayout = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 3rem;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    flex-direction: row;
    gap: 4rem;
  }

  > div {
    flex: 1;
  }
`;

export const SkillsFieldset = styled.fieldset`
  display: grid;
  gap: var(--vui-spacing-4);
  margin-bottom: var(--vui-spacing-4);
`;

export const SkillsError = styled.div`
  color: rgb(var(--vui-color-sentiment-negative));
  font: var(--vui-type-label-small-shorthand);
  letter-spacing: var(--vui-type-label-small-spacing);
`;

export const VideoContainer = styled.div`
  align-self: center;
  max-height: 19.4375rem;
  max-width: 19.4375rem;

  @media (min-width: ${DIMENSIONS.BREAKPOINT_MD}) {
    max-height: 31.75rem;
    max-width: 31.75rem;
  }

  video {
    max-height: 100%;
    position: unset;

    @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
      position: inherit;
    }
  }
`;
