import styled, { css } from "styled-components";

// TODO: Verify colours later

export const CardContainer = styled.div<{ $active?: boolean }>`
  background: rgb(var(--vui-color-surface-secondary));
  border: 1px solid rgb(var(--vui-color-surface-secondary-outline));
  border-radius: var(--vui-shape-corner-medium);
  color: rgb(var(--vui-color-on-surface-secondary));
  display: grid;
  grid-template-columns: calc(var(--vui-spacing-24) + var(--vui-spacing-4)) 1fr;
  gap: 0px;
  min-height: var(--vui-spacing-24);
  overflow: hidden;

  & > :only-child {
    grid-column: span 2;
  }

  /* TODO: Design approach for this differs from m3 state principles, clarify */
  &:hover {
    background-color: rgb(var(--vui-color-surface-primary));
  }

  ${({ $active }) =>
    $active &&
    css`
      background-color: rgb(var(--vui-color-surface-primary));
    `}
`;

export const ActiveCard = styled(CardContainer)`
  background: rgb(var(--vui-color-surface-primary));
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: var(--vui-spacing-4);
`;

// TODO: Reevaluate base element here in case we often want to change heading level
// (e.g. making it a div and using styled components at prop to force into a heading)
export const CardTitle = styled.h3`
  color: rgb(var(--vui-color-on-surface-secondary));
  font: var(--vui-type-title-small-shorthand);
  letter-spacing: var(--vui-type-title-small-spacing);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

// TODO: See above
export const CardSubtitle = styled.h4`
  color: rgb(var(--vui-color-on-surface-secondary-variant));
  font-weight: var(--vui-type-weight-semibold);
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
`;

export const CardFooter = styled.div`
  color: rgb(var(--vui-color-on-surface-secondary));
  font: var(--vui-type-title-small-shorthand);
  letter-spacing: var(--vui-type-title-small-spacing);
  margin-top: auto;
`;

export const CardImage = styled.img`
  aspect-ratio: 1 / 1;
  display: block;
  min-height: 100%;
  width: calc(var(--vui-spacing-24) + var(--vui-spacing-4));
`;
