import { useQuery } from "@apollo/client";
import DATE_FORMATS from "creators/constants/dateFormats";
import IDS from "creators/constants/ids";
import { GET_TAXONOMY_BY_CURRENCY_CODE } from "creators/queries/GetTaxonomyByCurrencyCode";
import {
  BriefType,
  GetTaxonomyByCurrencyCodeQuery as IGetTaxonomyByCurrencyCodeQuery,
} from "creators/types/graphql";
import calculateTimeBetween from "creators/utils/calculateTimeBetween";
import formatTimeBetween from "creators/utils/formatTimeBetween";
import offsetSubmissionDate from "creators/utils/offsetSubmissionDate";
import textualize from "creators/utils/textualize";
import { format } from "date-fns-tz";
import Tooltip from "vui/components/Tooltip";
import HelpIcon from "vui/icons/help.svg";
import {
  Container,
  Details,
  List,
  StyledHeading,
  StyledIcon,
  Term,
} from "./styles";

export interface ISummarySection {
  id: string;
  label: React.ReactNode;
  value: React.ReactNode;
}

export interface IProps {
  assets: string;
  className?: string;
  creatorsDeliverables: string;
  featuring: string;
  location: string;
  numberOfCreators: number;
  paymentAmount: number;
  paymentCurrencyCode: string;
  propsOrItems: string;
  selectionDeadline?: Date;
  submissionDeadline?: Date;
  type: BriefType;
  viewerPaymentAmount: number;
  viewerPaymentCurrencyCode: string;
}

const BriefTeaserSummary: React.FC<IProps> = ({
  assets,
  className,
  creatorsDeliverables,
  featuring,
  location,
  numberOfCreators,
  paymentAmount,
  paymentCurrencyCode,
  propsOrItems,
  selectionDeadline,
  submissionDeadline,
  type,
  viewerPaymentAmount,
  viewerPaymentCurrencyCode,
}) => {
  const { data } = useQuery<IGetTaxonomyByCurrencyCodeQuery>(
    GET_TAXONOMY_BY_CURRENCY_CODE,
    {
      variables: { paymentCurrencyCode, viewerPaymentCurrencyCode },
    },
  );

  const offsetSubmissionDeadline = submissionDeadline
    ? offsetSubmissionDate(submissionDeadline)
    : undefined;

  let workingTime;

  if (selectionDeadline && offsetSubmissionDeadline) {
    const timeBetween = calculateTimeBetween(
      selectionDeadline,
      offsetSubmissionDeadline,
    );

    if (timeBetween.hours > 0 || timeBetween.minutes > 0) {
      // Round up to the nearest day
      timeBetween.days = timeBetween.days + 1;
      timeBetween.hours = 0;
      timeBetween.minutes = 0;
    }

    workingTime = formatTimeBetween(timeBetween, true);
  }

  const summary: ISummarySection[] = [];

  // Payment
  if (paymentAmount) {
    const briefCurrencySymbol = data
      ? String.fromCodePoint(
          ...(data.paymentCurrencyCode.symbolCodePoints || []),
        )
      : "";

    const briefPayment = `${briefCurrencySymbol}${paymentAmount}`;
    let payment = briefPayment;
    if (paymentCurrencyCode !== viewerPaymentCurrencyCode) {
      const viewerCurrencySymbol = data
        ? String.fromCodePoint(
            ...(data.viewerPaymentCurrencyCode.symbolCodePoints || []),
          )
        : "";
      const viewerPayment = `${viewerCurrencySymbol}${viewerPaymentAmount}`;
      payment = `${briefPayment} / ${viewerPayment}`;
    }

    summary.push({
      id: IDS.BRIEF_TEASER.SUMMARY.PAYMENT.ID,
      label: textualize("liveBriefs.teaser.summary.payment.label"),
      value: (
        <>
          {textualize("liveBriefs.teaser.summary.payment.bonuses", {
            payment,
          })}
          {paymentCurrencyCode !== viewerPaymentCurrencyCode && (
            <Tooltip
              id={IDS.BRIEF.INTRODUCTION.PAYMENT_TOOLTIP}
              supportingText={
                textualize("liveBriefs.teaser.summary.payment.tooltip", {
                  payment: briefPayment,
                }) as string
              }
            >
              <StyledIcon component={HelpIcon} />
            </Tooltip>
          )}
        </>
      ),
    });
  }

  // Deliverables
  if (creatorsDeliverables) {
    summary.push({
      id: IDS.BRIEF_TEASER.SUMMARY.DELIVERABLES,
      label: textualize("liveBriefs.teaser.summary.deliverables"),
      value: creatorsDeliverables,
    });
  }

  // Submission deadline
  if (offsetSubmissionDeadline) {
    summary.push({
      id: IDS.BRIEF_TEASER.SUMMARY.DEADLINE,
      label: textualize("liveBriefs.teaser.summary.deadline"),
      value: format(offsetSubmissionDeadline, DATE_FORMATS.DATE_TIME),
    });
  }

  // Working time
  if (selectionDeadline && offsetSubmissionDeadline) {
    summary.push({
      id: IDS.BRIEF_TEASER.SUMMARY.WORKING_TIME,
      label: textualize("liveBriefs.teaser.summary.workingTime.label"),
      value: textualize("liveBriefs.teaser.summary.workingTime.timeToCreate", {
        time: workingTime,
      }),
    });
  }

  // Assets (MOTION GRAPHICS ONLY)
  if (type === BriefType.MotionGraphics && assets) {
    summary.push({
      id: IDS.BRIEF_TEASER.SUMMARY.ASSETS,
      label: textualize("liveBriefs.teaser.summary.assets"),
      value: assets,
    });
  }

  // LIVE ACTION SUMMARY
  if (type === BriefType.LiveAction) {
    // Starring
    if (featuring) {
      summary.push({
        id: IDS.BRIEF_TEASER.SUMMARY.STARRING,
        label: textualize("liveBriefs.teaser.summary.starring"),
        value: featuring,
      });
    }

    // Filming location
    if (location) {
      summary.push({
        id: IDS.BRIEF_TEASER.SUMMARY.LOCATION,
        label: textualize("liveBriefs.teaser.summary.location"),
        value: location,
      });
    }

    // Props
    if (propsOrItems) {
      summary.push({
        id: IDS.BRIEF_TEASER.SUMMARY.PROPS,
        label: textualize("liveBriefs.teaser.summary.props"),
        value: propsOrItems,
      });
    }
  }

  // Number of creators to be selected
  if (numberOfCreators) {
    summary.push({
      id: IDS.BRIEF_TEASER.SUMMARY.NUMBER_OF_CREATORS,
      label: textualize("liveBriefs.teaser.summary.numberOfCreators"),
      value: numberOfCreators,
    });
  }

  return (
    <Container>
      <StyledHeading appearance="small" level="2">
        Requirements
      </StyledHeading>
      <List className={className}>
        {summary.map((item) => (
          <div key={item.id}>
            <Term>{item.label}</Term>
            <Details>{item.value}</Details>
          </div>
        ))}
      </List>
    </Container>
  );
};

export default BriefTeaserSummary;
