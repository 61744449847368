import styled, { keyframes } from "styled-components";
import Menu from "vui/components/Menu";
import ArrowDropDownIcon from "vui/icons/arrow-drop-down.svg";
import ProgressActivityIcon from "vui/icons/progress-activity.svg";

export const Container = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

export const Options = styled(Menu)<{ dropdownPosition: string }>`
  max-height: var(--vui-spacing-64);
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 2;

  ${({ dropdownPosition }) =>
    dropdownPosition === "below"
      ? "top: var(--vui-spacing-14);"
      : "bottom: 100%"}
`;

export const MenuClosedIcon = ArrowDropDownIcon;
export const MenuOpenIcon = styled(ArrowDropDownIcon)`
  rotate: 180deg;
`;

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingIcon = styled(ProgressActivityIcon)`
  animation: ${rotate} 1.5s linear infinite;
`;

export const Message = styled.div`
  padding: 0 var(--vui-spacing-3);
  text-align: center;
`;
