import {
  BlockContainer,
  errorStyling,
  InnerContainer,
  IProps,
} from "creators/components/Brief/BlockContainer/BlockContainer";
import DIMENSIONS from "creators/constants/dimensions";
import FONTS from "creators/constants/fonts";
import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";
import BREAKPOINTS from "vui/constants/breakpoints";

// TODO: Due to being used in Internal, fallback values have been added to all CSS variables (except fonts) in this package.
// This is the only way to avoid breaking the appearance of Internal using these components for now.

export const ExpandingTextarea = styled(TextareaAutosize)`
  border: none;
  font: inherit;
  min-height: 120%;
`;

export const Container = styled(BlockContainer)<IProps>`
  max-width: none;

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_LG}) {
    max-width: 45.75rem;
  }

  ${InnerContainer} {
    border: 0.125rem solid
      rgb(var(--vui-color-sentiment-informative, 53, 97, 220));
    border-radius: 0.5rem;
    font-size: 1.25rem;
    font-weight: ${FONTS.WEIGHT.SEMI_BOLD};
    overflow: hidden; /* Must Include header overlaps border without this */

    ${({ error }) => error && errorStyling}

    &:last-child {
      margin-bottom: 0;
    }

    ul {
      list-style: disc;
      padding: var(--vui-spacing-4, 1rem) var(--vui-spacing-10, 2.5rem);

      li {
        margin-bottom: var(--vui-spacing-4, 1rem);

        &:last-child {
          margin-bottom: 0;
        }

        ${ExpandingTextarea} {
          resize: none;
          vertical-align: top;
          width: calc(100% - 2rem);
        }

        & > div > p {
          font-size: inherit;
          line-height: inherit;
        }
      }
    }

    @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
      font-size: 1.5rem;

      ul {
        columns: 2;
        padding: var(--vui-spacing-8, 2rem) var(--vui-spacing-16, 4rem) 0;

        li {
          margin-bottom: 0;

          > div {
            vertical-align: top;
          }

          > div,
          > textarea {
            border-bottom: 1px solid transparent;
            display: inline-block;
            font: var(--vui-type-title-medium-shorthand);
            letter-spacing: var(--vui-type-title-medium-spacing);
            margin-bottom: var(--vui-spacing-8, 2rem);
            width: 100%;
          }

          > textarea {
            &:focus {
              border-color: rgb(
                var(--vui-color-sentiment-informative, 53, 97, 220)
              );
              outline: none;
            }
          }
        }
      }
    }
  }
`;

export const Header = styled.h4`
  background: rgb(var(--vui-color-sentiment-informative, 53, 97, 220));
  color: rgb(var(--vui-color-on-sentiment-informative, 255, 255, 255));
  font: var(--vui-type-title-medium-shorthand);
  letter-spacing: var(--vui-type-title-medium-spacing);
  padding: var(--vui-spacing-4, 1rem);

  @media (min-width: ${DIMENSIONS.BREAKPOINT_LG}) {
    font: var(--vui-type-title-large-shorthand);
    letter-spacing: var(--vui-type-title-large-spacing);
    padding: var(--vui-spacing-6, 1.5rem) var(--vui-spacing-8, 2rem);
  }
`;
