import {
  forwardRef,
  FunctionComponent,
  InputHTMLAttributes,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import {
  IconButton,
  Input,
  InputWrapper,
  Label,
  LeadingIconContainer,
  StyledIcon,
  SupportingContainer,
  SupportingText,
  TrailingElementContainer,
  WordCount,
  Wrapper,
} from "./styles";

export interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  hasError?: boolean;
  label?: ReactNode;
  leadingIcon?: FunctionComponent;
  leadingIconLabel?: ReactNode;
  multiline?: boolean;
  onLeadingIconClick?: MouseEventHandler<HTMLButtonElement>;
  supportingText?: ReactNode;
  trailingElement?: ReactNode;
  value?: string;
}

export interface ITextInputIconProps {
  disabled: boolean;
  icon: FunctionComponent;
  label: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const TextInputIcon = ({
  disabled,
  icon,
  label,
  onClick,
}: ITextInputIconProps) => {
  const coreIcon = (
    <StyledIcon component={icon} isDisabled={disabled} label={label} />
  );

  if (!onClick) {
    return coreIcon;
  }

  return (
    <IconButton onClick={onClick} type="button">
      {coreIcon}
    </IconButton>
  );
};

export const TextInput = forwardRef<HTMLInputElement, IProps>(
  (
    {
      className,
      disabled = false,
      hasError = false,
      id,
      label,
      leadingIcon,
      leadingIconLabel,
      maxLength,
      multiline,
      onChange,
      onLeadingIconClick,
      supportingText,
      trailingElement,
      value,
      ...props
    }: IProps,
    ref,
  ) => {
    const textInputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => textInputRef.current as HTMLInputElement);

    useEffect(() => {
      if (!multiline) {
        return;
      }

      if (textInputRef.current) {
        textInputRef.current.style.height = "0px";
        const scrollHeight = Math.floor(textInputRef.current?.scrollHeight - 1);
        textInputRef.current.style.height = `${scrollHeight}px`;
      }
    }, [multiline, value]);

    return (
      <Wrapper className={className}>
        <InputWrapper
          className={className}
          hasError={hasError}
          isDisabled={disabled}
        >
          {leadingIcon && (
            <LeadingIconContainer>
              <TextInputIcon
                disabled={disabled}
                icon={leadingIcon}
                label={leadingIconLabel}
                onClick={onLeadingIconClick}
              />
            </LeadingIconContainer>
          )}

          {label && (
            <Label
              hasLeadingIcon={!!leadingIcon}
              hasTrailingElement={!!trailingElement}
              hasValue={!!value}
              htmlFor={id}
              isDisabled={disabled}
            >
              {label}
            </Label>
          )}

          <Input
            $multiline={!!multiline}
            aria-describedby={
              supportingText ? `${id}-supporting-text` : undefined
            }
            aria-invalid={hasError}
            as={multiline ? "textarea" : "input"}
            disabled={disabled}
            hasLabel={!!label}
            hasLeadingIcon={!!leadingIcon}
            hasTrailingElement={!!trailingElement}
            id={id}
            isDisabled={disabled}
            onChange={onChange}
            ref={textInputRef}
            rows={multiline ? 1 : undefined}
            value={value}
            {...props}
          />

          {trailingElement && (
            <TrailingElementContainer>
              {trailingElement}
            </TrailingElementContainer>
          )}
        </InputWrapper>

        {(multiline || supportingText) && (
          <SupportingContainer>
            {supportingText && (
              <SupportingText
                hasError={hasError}
                id={`${id}-supporting-text`}
                isDisabled={disabled}
              >
                {supportingText}
              </SupportingText>
            )}
            {multiline && maxLength && (
              <WordCount
                hasError={value?.length ? value?.length > maxLength : false}
                id={`${id}-word-count`}
                isDisabled={disabled}
              >
                {value?.length} / {maxLength}
              </WordCount>
            )}
          </SupportingContainer>
        )}
      </Wrapper>
    );
  },
);

export default TextInput;
