import styled, { css } from "styled-components";
import Icon from "vui/components/Icon";

export const Container = styled.li`
  list-style: none;
`;

export const Item = styled.button<{ selected?: boolean }>`
  appearance: none;
  align-items: center;
  background-color: rgb(var(--vui-color-surface-secondary));
  border: 0;
  color: rgb(var(--vui-color-on-surface-secondary));
  display: flex;
  font: var(--vui-type-label-large-shorthand);
  gap: var(--vui-spacing-3);
  letter-spacing: var(--vui-type-label-large-spacing);
  min-height: var(--vui-spacing-12);
  padding: var(--vui-spacing-1) var(--vui-spacing-3);
  text-align: left;
  width: 100%;

  :active:not(:disabled),
  :focus-visible:not(:disabled),
  :hover:not(:disabled) {
    background-color: rgb(var(--vui-color-surface-tertiary));
    cursor: pointer;
  }

  :focus-visible {
    outline: rgb(var(--vui-color-surface-tertiary-outline)) solid
      var(--vui-state-focus-indicator-thickness);
    outline-offset: calc(-1.5 * var(--vui-state-focus-indicator-outer-offset));
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: rgb(var(--vui-color-inverse-surface));
      color: rgb(var(--vui-color-inverse-on-surface));

      :active:not(:disabled),
      :focus-visible:not(:disabled),
      :hover:not(:disabled) {
        background-color: rgb(var(--vui-color-active-surface));
        color: rgb(var(--vui-color-active-on-surface));
      }
    `};

  :disabled {
    color: rgba(var(--vui-color-disabled-on-surface), 0.5);
  }
`;

export const LeadingIcon = styled(Icon)<{
  isDisabled: boolean;
  isSelected: boolean;
}>`
  color: rgb(var(--vui-color-on-surface-secondary));
  flex: 0 0 auto;
  height: var(--vui-spacing-6);
  line-height: 1;
  width: var(--vui-spacing-6);

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: rgb(var(--vui-color-inverse-on-surface));
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: rgba(var(--vui-color-disabled-on-surface), 0.5);
    `};
`;

export const TrailingIcon = styled(LeadingIcon)`
  margin-left: auto;
`;
