import { SecondaryButton } from "creators/components/Button";
import CloseIcon from "creators/images/icons/cross.svg";
import textualize from "creators/utils/textualize";
import { ReactNode } from "react";
import Icon from "shared/components/Icon";
import styled from "styled-components";

export interface IProps {
  className?: string;
  icon?: ReactNode;
  id?: string;
  label?: string | ReactNode;
  onClick: () => void;
}

const StyledButton = styled(SecondaryButton)`
  background: rgb(var(--vui-color-surface-primary));
  border: none;
  color: var(--vui-color-on-surface-primary);
  padding: 0.875rem;
  width: auto;

  :active {
    border: none;
  }
`;

const StyledIcon = styled(Icon)`
  display: block;
`;

export const NavigationButton: React.FC<React.PropsWithChildren<IProps>> = ({
  className,
  icon = CloseIcon,
  id,
  label = textualize("common.close"),
  onClick,
}) => (
  <StyledButton className={className} id={id} onClick={onClick}>
    <StyledIcon component={icon} label={label} />
  </StyledButton>
);

export default NavigationButton;
