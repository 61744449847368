import Countdown from "creators/components/Countdown";
import DATE_FORMATS from "creators/constants/dateFormats";
import IDS from "creators/constants/ids";
import { BriefState, BriefType, SubmissionState } from "creators/types/graphql";
import textualize from "creators/utils/textualize";
import { format } from "date-fns";
import { ElementType, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import formatNumberToCurrency from "shared/utils/formatNumberToCurrency";
import styled from "styled-components";
import {
  CardContainer,
  CardContent,
  CardFooter,
  CardImage,
  CardSubtitle,
  CardTitle,
} from "vui/components/Card";

const StyledCardImage = styled(CardImage)`
  aspect-ratio: 1 / 1;
  object-fit: cover;
  width: 100%;
`;

export interface IProps {
  brandID: string;
  brandImage: string;
  brandName: string;
  briefID: string;
  briefState: BriefState;
  briefTitle: string;
  briefType: BriefType;
  creatorSelectionDate?: Date;
  headingLevel?: number;
  paymentAmount: number;
  paymentCurrencyCode: string;
  slug: string;
  submissionState?: SubmissionState;
  submittedAt?: Date;
}

const BriefCard: React.FC<IProps> = ({
  brandImage,
  brandName,
  briefID,
  briefState,
  briefTitle,
  creatorSelectionDate,
  headingLevel,
  paymentAmount,
  paymentCurrencyCode,
  slug,
  submissionState,
  submittedAt,
}) => {
  // TODO: Look at removing this state in favour of swapping textualize based on remaining days
  const [applicationsClosed, setApplicationsClosed] = useState(
    briefState === BriefState.Closed ||
      (creatorSelectionDate && creatorSelectionDate <= new Date()),
  );

  const closeApplications = useCallback(() => {
    setApplicationsClosed(true);
  }, []);

  const completed =
    submittedAt && submissionState === SubmissionState.Completed;
  const didNotSubmit =
    !submittedAt && submissionState === SubmissionState.Completed;

  // Card Subtitle text
  let status;
  if (completed) {
    status = textualize("vuiLiveBriefs.briefs.status.submittedDate", {
      date: format(submittedAt!, DATE_FORMATS.SHORT_DATE),
    });
  } else if (didNotSubmit) {
    status = textualize("vuiLiveBriefs.briefs.status.didNotSubmit");
  } else if (submissionState === SubmissionState.Applied) {
    status = textualize("vuiLiveBriefs.briefs.status.applied");
  } else if (submissionState === SubmissionState.InviteDeclined) {
    status = textualize("vuiLiveBriefs.briefs.status.inviteDeclined");
  } else if (submissionState === SubmissionState.Declined) {
    status = textualize("vuiLiveBriefs.briefs.status.declined");
  } else if (applicationsClosed) {
    status = textualize("vuiLiveBriefs.briefs.status.applicationsClosed");
  } else {
    status = textualize("vuiLiveBriefs.briefs.status.timeToApply", {
      time: creatorSelectionDate ? (
        <Countdown
          date={creatorSelectionDate}
          largestOnly={true}
          onComplete={closeApplications}
        />
      ) : (
        ""
      ),
    });
  }

  const linkPath = `/${completed ? "previous-briefs" : "brief"}/${briefID}/${slug}`;

  const headingElements = headingLevel
    ? {
        title: `h${headingLevel}` as ElementType,
        subtitle: `h${headingLevel + 1}` as ElementType,
      }
    : {};

  const content = (
    <CardContainer $active={location.pathname === linkPath}>
      <StyledCardImage alt={brandName} src={brandImage} />
      <CardContent>
        <CardTitle as={headingElements.title}>{briefTitle}</CardTitle>
        <CardSubtitle as={headingElements.subtitle}>{status}</CardSubtitle>
        <CardFooter>
          {formatNumberToCurrency(paymentAmount, "GB", paymentCurrencyCode)}
        </CardFooter>
      </CardContent>
    </CardContainer>
  );

  if (didNotSubmit) {
    return <div id={`${IDS.BRIEF_CARD.COMPONENT}${briefID}`}>{content}</div>;
  }

  return (
    <Link id={`${IDS.BRIEF_CARD.COMPONENT}${briefID}`} to={linkPath}>
      {content}
    </Link>
  );
};

export default BriefCard;
