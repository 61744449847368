// TODO: Update to pure VUI
import ErrorPage from "creators/pages/Error";
import textualize from "creators/utils/textualize";
import { ComponentType } from "react";
import { useHistory } from "react-router-dom";
import ErrorBoundary from "shared/components/ErrorBoundary";

export interface IProps {
  children: React.ReactNode;
  fallback?: ComponentType<any>;
}

export const FallbackComponent = () => (
  <ErrorPage>
    <h1>{textualize("error.fallback.heading")}</h1>
    <span>{textualize("error.fallback.body")}</span>
  </ErrorPage>
);

export const CrashReporterBoundary = ({ children, fallback: X }: IProps) => {
  let Fallback = X;

  if (!Fallback) {
    Fallback = FallbackComponent;
  }

  const history = useHistory();

  return (
    <ErrorBoundary FallbackComponent={Fallback} history={history}>
      {children}
    </ErrorBoundary>
  );
};

export default CrashReporterBoundary;
