import {
  Children,
  KeyboardEventHandler,
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  RefObject,
  cloneElement,
  isValidElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import KEYBOARD_SHORTCUTS from "vui/constants/keyboardShortcuts";
import textualize from "vui/utils/textualize";
import {
  ArrowButton,
  Container,
  NextArrow,
  PreviousArrow,
  Scroller,
} from "./styles";

export interface IProps {
  ariaLabel?: string;
  className?: string;
  id?: string;
  onPreviousClick: (e: MouseEvent<HTMLButtonElement>) => void;
  onNextClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

const enum Direction {
  Backwards,
  Forwards,
}

export const HorizontalSubNav = ({
  ariaLabel,
  children,
  className,
  id,
  onPreviousClick,
  onNextClick,
}: PropsWithChildren<IProps>) => {
  const activeRef = useRef<HTMLButtonElement>(null);
  const focusTargetRef = useRef<HTMLButtonElement>(null);
  const scrollerRef = useRef<HTMLDivElement>(null);
  const firstUpdate = useRef(true);

  const activeIndex = useMemo(() => {
    const activeChild = Children.toArray(children).findIndex(
      (child) => isValidElement(child) && child.props.$active,
    );

    return activeChild === -1 ? null : activeChild;
  }, [children]);

  const [focusIndex, setFocusIndex] = useState(activeIndex ?? 0);
  const [showArrows, setShowArrows] = useState(false);

  const showNextArrow = useMemo(
    () =>
      showArrows &&
      activeIndex !== null &&
      activeIndex < Children.count(children) - 1,
    [activeIndex, children, showArrows],
  );
  const showPreviousArrow = useMemo(
    () => showArrows && activeIndex !== null && activeIndex > 0,
    [activeIndex, showArrows],
  );

  const items = Children.map(children, (child, index) => {
    if (!isValidElement(child)) {
      return null;
    }

    const isFocusTarget = focusIndex === index;

    let ref: RefObject<HTMLButtonElement> | undefined;

    if (activeIndex === index) {
      ref = activeRef;
    } else if (isFocusTarget) {
      ref = focusTargetRef;
    }

    return cloneElement(child as ReactElement, {
      ref,
      tabIndex: isFocusTarget ? 0 : -1,
    });
  });

  const findNextFocusableChildIndex = useCallback(
    (direction: Direction = Direction.Forwards) => {
      let nextChildren = Children.toArray(children).slice(focusIndex + 1);

      if (direction === Direction.Backwards) {
        nextChildren = Children.toArray(children)
          .slice(0, focusIndex)
          .reverse();
      }

      const nextChildrenIndex = nextChildren.findIndex((child) =>
        isValidElement(child),
      );

      if (nextChildrenIndex === -1) {
        return focusIndex;
      }

      if (direction === Direction.Backwards) {
        return focusIndex - nextChildrenIndex - 1;
      }

      return focusIndex + nextChildrenIndex + 1;
    },
    [focusIndex, children],
  );

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      switch (event.key) {
        case KEYBOARD_SHORTCUTS.ARROW_LEFT:
          event.preventDefault();
          setFocusIndex(findNextFocusableChildIndex(Direction.Backwards));
          break;
        case KEYBOARD_SHORTCUTS.ARROW_RIGHT:
          event.preventDefault();
          setFocusIndex(findNextFocusableChildIndex(Direction.Forwards));
          break;
      }
    },
    [findNextFocusableChildIndex],
  );

  useEffect(() => {
    const focusTarget = focusTargetRef.current || activeRef.current;
    if (!firstUpdate.current && focusTarget) {
      focusTarget.focus();
    }
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
  }, [focusIndex]);

  const scrollerResized = useCallback((entries: ResizeObserverEntry[]) => {
    const scroller = entries[0].target;

    const isOverflowing = scroller.clientWidth < scroller.scrollWidth;

    setShowArrows(isOverflowing);
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver(scrollerResized);
    if (scrollerRef.current) {
      observer.observe(scrollerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [scrollerResized]);

  useLayoutEffect(() => {
    if (activeIndex !== null && activeRef.current && scrollerRef.current) {
      const scrollPos =
        activeRef.current.offsetLeft -
        scrollerRef.current.offsetLeft -
        scrollerRef.current.clientWidth / 2 +
        activeRef.current.clientWidth / 2;

      scrollerRef.current.scrollTo({
        left: scrollPos,
      });
    }
  }, [activeIndex, showPreviousArrow, showNextArrow]);

  return (
    <Container aria-label={ariaLabel} className={className} id={id}>
      {showPreviousArrow && (
        <ArrowButton
          aria-label={textualize("navigation.previous") as string}
          onClick={onPreviousClick}
        >
          <PreviousArrow />
        </ArrowButton>
      )}

      <Scroller onKeyDown={handleKeyDown} ref={scrollerRef}>
        {items}
      </Scroller>

      {showNextArrow && (
        <ArrowButton
          aria-label={textualize("navigation.next") as string}
          onClick={onNextClick}
        >
          <NextArrow />
        </ArrowButton>
      )}
    </Container>
  );
};

export default HorizontalSubNav;
