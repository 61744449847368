import { forwardRef } from "react";
import styled, { css } from "styled-components";
import Switch, { IProps as ISwitchProps } from "vui/components/Switch";
import BREAKPOINTS from "vui/constants/breakpoints";

const Label = styled.label`
  color: rgb(var(--vui-color-on-surface-secondary));
  font: var(--vui-type-label-large-shorthand);
  letter-spacing: var(--vui-type-label-large-spacing);
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SwitchContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: var(--vui-spacing-8);
  justify-content: space-between;

  @media (min-width: ${BREAKPOINTS.BREAKPOINT_SM}) {
    gap: var(--vui-spacing-20);
  }
`;

const SwitchWrapper = styled.div`
  display: flex;
  flex: 0 1 auto;
`;

const SupportingText = styled.div<{
  hasError: boolean;
}>`
  color: rgb(var(--vui-color-on-surface-secondary-variant));
  font: var(--vui-type-label-small-shorthand);
  letter-spacing: var(--vui-type-label-small-spacing);
  line-height: var(--vui-type-line-height-md);
  padding: var(--vui-spacing-1) 0;

  ${({ hasError }) =>
    hasError &&
    css`
      color: rgb(var(--vui-color-sentiment-negative));
    `};
`;
export interface IProps extends ISwitchProps {
  label?: React.ReactNode | string;
  supportingText?: React.ReactNode | string;
}

const SwitchInput = forwardRef<HTMLInputElement, IProps>(
  (
    {
      "aria-describedby": switchDescribedBy,
      hasError = false,
      id,
      label,
      supportingText,
      ...switchProps
    }: IProps,
    ref,
  ) => {
    const supportingTextID = `${id}-supporting-text`;
    return (
      <SwitchContainer>
        <LabelContainer>
          {label && <Label htmlFor={id}>{label}</Label>}
          {supportingText && (
            <SupportingText hasError={hasError} id={supportingTextID}>
              {supportingText}
            </SupportingText>
          )}
        </LabelContainer>
        <SwitchWrapper>
          <Switch
            aria-describedby={
              supportingText
                ? [supportingTextID, switchDescribedBy]
                    .filter((id) => !!id)
                    .join(" ")
                : switchDescribedBy
            }
            hasError={hasError}
            id={id}
            ref={ref}
            {...switchProps}
          />
        </SwitchWrapper>
      </SwitchContainer>
    );
  },
);

export default SwitchInput;
