import {
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useDelayGroup,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
  UseRoleProps,
} from "@floating-ui/react";
import {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  useState,
} from "react";

export interface IOptions {
  role?: Extract<UseRoleProps["role"], "tooltip" | "label">;
  triggerProps?: Record<string, unknown>;
}

const useTooltip = (trigger: ReactNode, options: IOptions) => {
  const { role = "tooltip", triggerProps = {} } = options;

  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    middleware: [
      flip({ fallbackAxisSideDirection: "start" }),
      offset(2),
      shift({
        crossAxis: true,
        mainAxis: true,
        padding: 8,
      }),
    ],
    onOpenChange: setIsOpen,
    open: isOpen,
    placement: "top",
    whileElementsMounted: autoUpdate,
  });

  const { delay } = useDelayGroup(context);
  const hover = useHover(context, {
    delay,
    handleClose: safePolygon(),
  });
  const focus = useFocus(context);
  const roleInteraction = useRole(context, { role });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    roleInteraction,
  ]);

  const triggerWithProps = Children.map(trigger, (child) => {
    if (!isValidElement(child)) {
      return child;
    }

    return cloneElement(child as ReactElement, {
      ref: refs.setReference,
      ...getReferenceProps(triggerProps),
    });
  });

  return {
    floatingProps: {
      ref: refs.setFloating,
      style: floatingStyles,
      ...getFloatingProps(),
    },
    isOpen,
    refs,
    trigger: triggerWithProps,
  };
};

export default useTooltip;
